import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as HyperFlowAlert } from "../../../../Assets/alerts/hyperFlowAlerts.svg";

import {
  emptyCurrentAttatchment,
  getAttatchmentData,
  selectCurrentAttatchment,
  selectAttatchmentDownloadingStatus,
  selectLoadingAttachmentZip,
  getAttachmentsZip,
  selectAttachmentToVisualize,
  setAttachmentToVisualize,
} from "../../coreid/coreIdSlice";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import Modal, { Styles } from "react-modal";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { useEffect, useState } from "react";
import {
  ExternalQueryResult,
  ListsRenderResult,
  MultiFactorMatrixData,
} from "../../coreid/coreIdAPI";
import {
  Attatchment,
  AuthentikatorData,
  FilledForm,
} from "../../magicforms/magicFormsAPI";
import { BiometryImages, Generalmages } from "./components/CoreIdImages";
import {
  LoadingStatus,
  selectClientId,
  selectMetaClientId,
  selectRoles,
} from "../../../common/commonSlice";
import fileDownload from "js-file-download";
import {
  changeStatusById,
  getFlowById,
  getRevisionMode,
  changeIndex,
  selectCurrentFlow,
  selectIndex,
  setIsFlowDetail,
  changePageState,
  selectCurrentPageHyperFlow,
  selectFlowFilteredRevision,
  retryStepManual,
  selectLoadingHyperflow,
  selectLoadingImages,
  selectLoadingAlerts,
  getAttatchmentByRegistrationId,
  selectLoadingAttachments,
  getRegistrationImagesByRegistartionId,
  selectHyperAttachments,
  selectUserImages,
  selectCostumStates,
  getCustomStates,
  resolveCustomState,
  selectLoadingResolveState,
  selectLoadingShowcase,
  selectShowcase,
  getDataShowcaseById,
  selectLoadingHyperflowAlerts,
  selectHyperflowAlerts,
  getHyperflowAlerts,
  reExecuteStep,
  selectHyperflowError,
  selectManualInputData,
  selectLoadingManualInputData,
  changeManualInputState,
  getManualInputData,
  resolveManualInputData,
  selectShowRpaStatus,
  selectRpaStatus,
  selectRpaStatusLoading,
  getRpaStatus,
  selectDownloadSupportFilesLoading,
  selectSupportFiles,
  getSupportFiles,
} from "../hyperFlowSlice";
import { StateComponent } from "./components/StateComponent";
import { ExpandibleComponent } from "./ExpandibleComponent";
import {
  CustomStateItem,
  DataShowcase,
  ExecutedStep,
  ResolveCustomStateRequest,
  HyperFlow,
  SupportFile,
  uploadSupportFileAPI,
  getSupportFileAPI,
} from "../hyperFlowAPI";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByEntity,
  selectAllTags,
  selectCurrentEntity,
  selectCurrentTagCreation,
  selectCurrentTagsByEntity,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByEntity,
  setTagCreation,
} from "../../tags/tagSlice";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import { ReactComponent as ExternalIcon } from "../../../../Assets/common/images/externalLinkIcon.svg";
import { File } from "../../firma/firmaAPI";
import { ExpandibleComponentList } from "./ExpandibleComponentList";
import { UnifiedAlerts } from "../../alerts/alertsAPI";
import { AlertDot } from "../../alerts/components/AlertDot";
import { changeAlertStatus } from "../../alerts/alertsSlice";
import {
  base64PDFToBlobUrl,
  formatDateStandar,
} from "../../../../helpers/utils";
import { getStatusName, getStatusPagareName } from "../../firma/helpers/utils";
import {
  downloadFile,
  selectDownloadingStatus,
} from "../../pagares/pagareSlice";
import { DropDown } from "../../../common/components/Btns/DropDown";
import { getAsesores, selectAsesores } from "../../analytics/analyticsSlice";
import { ManualInputComponent } from "./components/ManualInputComponent";
import UploadSupportFileComponent from "./components/UploadSupportFileComponent";
import AuthentikatorDataComponent from "./components/AuthentikatorDataComponent";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "40%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};

const customStylesModalAuthentikator: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0,0,0,0.7)",
    borderRadius: "20px",
    border: "none",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "45%",
    overflow: "auto",
    backdropFilter: "blur(5px)",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
    backdropFilter: "blur(5px)",
  },
};

const customStylesListDetail: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "25%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");
export const Flowv2 = () => {
  let currentFlow = useAppSelector(selectCurrentFlow);
  let loadingHyperFlow = useAppSelector(selectLoadingHyperflow);
  let loadingAlerts = useAppSelector(selectLoadingAlerts);
  let loadingAttatchments = useAppSelector(selectLoadingAttachments);
  let loadingImages = useAppSelector(selectLoadingImages);
  let loadingShowcase = useAppSelector(selectLoadingShowcase);
  let downloadingAttatchment = useAppSelector(
    selectAttatchmentDownloadingStatus
  );
  let metaClientId = useAppSelector(selectMetaClientId);
  let clientId = useAppSelector(selectClientId);

  let currentAttatchment = useAppSelector(selectCurrentAttatchment);
  let flows = useAppSelector(selectFlowFilteredRevision);
  let index = useAppSelector(selectIndex);
  let currentPage = useAppSelector(selectCurrentPageHyperFlow);
  let currentAttachments = useAppSelector(selectHyperAttachments);
  let currentImages = useAppSelector(selectUserImages);
  let dataShowcase = useAppSelector(selectShowcase);
  let customStates = useAppSelector(selectCostumStates);
  let loadingResolveState = useAppSelector(selectLoadingResolveState);
  let loadingHyperflowAlerts = useAppSelector(selectLoadingHyperflowAlerts);
  let hyperflowError = useAppSelector(selectHyperflowError);
  let hyperFlowAlerts = useAppSelector(selectHyperflowAlerts);
  let downloadingPagareExtraDocs = useAppSelector(selectDownloadingStatus);
  const downloadingAttatchmentZip = useAppSelector(selectLoadingAttachmentZip);
  const attachmentToVisualize = useAppSelector(selectAttachmentToVisualize);
  const downloadingSupportFiles = useAppSelector(
    selectDownloadSupportFilesLoading
  );
  const currentSupportFiles = useAppSelector(selectSupportFiles);

  // Tag stuff
  let currentEntity = useAppSelector(selectCurrentEntity);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let allTags = useAppSelector(selectAllTags);
  let currentEntityTags = useAppSelector(selectCurrentTagsByEntity);
  let loadingEntityTags = useAppSelector(selectLoadingCurrentTagsByEntity);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState(false);
  let roles = useAppSelector(selectRoles);

  // Asesores
  let asesores = useAppSelector(selectAsesores);

  //ManualInput Stuff
  let manualInputData = useAppSelector(selectManualInputData);
  let loadingManualInputData = useAppSelector(selectLoadingManualInputData);

  //Support files Stuff
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [supportFileName, setSupportFileName] = useState("");
  const [supportFile, setSupportFile] = useState<globalThis.File | null>(null);
  const [supportName, setSupportName] = useState("");

  //AuthentikatorData Stuff
  const [authentikatorData, setAuthentikatorData] =
    useState<AuthentikatorData | null>(null);
  const [showAuthentikatorDataModal, setShowAuthentikatorDataModal] =
    useState(false);

  //RpaStatus stuff
  let showRpaStatus = useAppSelector(selectShowRpaStatus);
  let rpaStatus = useAppSelector(selectRpaStatus);
  let rpaStatusLoading = useAppSelector(selectRpaStatusLoading);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { id } = useParams();
  const [idRevisionCurrent, setidRevisionCurrent] = useState("");

  const [certificado, setCertificado] = useState(false);
  const [cartaInstruccionesShow, setCartaInstruccionesShow] = useState(false);
  const [servicealerts, setservicealerts] = useState<string[]>([]);
  const [servicealertsn, setservicealertsn] = useState(false);
  const [modoRevision, setmodoRevision] = useState(false);
  const [modalDataAlertsOpen, setModalDataAlertsOpen] = useState(false);
  const [currentAlert, setCurrentAlert] = useState<UnifiedAlerts>({
    date: "",
    description: "",
    id: "",
    entityId: "",
    service: "",
    category: "",
    userId: "",
    criticality: "",
    status: "",
    name: "",
    userName: "",
  });
  const [listDetailOpen, setListDetailOpen] = useState(false);

  const [listDetail, setListDetail] = useState<Array<string>>([]);
  const [listSelected, setListSelected] = useState<ExternalQueryResult | null>(
    null
  );

  let downloadAtatchmentPagares = (fileName: string, index: number) => {
    dispatch(downloadFile({ fileName, index }));
  };

  const getAttachmentIds = (attachments: Array<Attatchment>) => {
    let ids: Array<string> = [];
    attachments.forEach((att) => {
      if (ids.includes(att.filledFormId)) return;
      ids.push(att.filledFormId);
    });
    return ids;
  };

  const getAttachmentRender = (mimeType: string) => {
    switch (mimeType) {
      case "application/pdf":
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width="100%"
            height="100%"
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
      case "image/png":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );
      case "image/jpeg":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "image/jpg":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "image/gif":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "video/mp4":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/mp4" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/ogg":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/mpeg":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/webm":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/x-msvideo":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      default:
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width="100%"
            height="100%"
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
    }
  };

  const getCallToAction = (alert: UnifiedAlerts) => {
    if (
      clientId !== "638ff0eb7c1dfb733a0183d0" &&
      metaClientId !== "638ff0eb7c1dfb733a0183d0"
    )
      return <></>;
    if (alert.category === "COMPLIANCE") {
      return (
        <>
          <p
            style={{
              fontSize: "10px",
              fontWeight: 600,
              color: "#F7CD00",
              width: "60%",
              margin: "0 auto 6px auto",
            }}
          >
            Debido al tipo de alerta, recomendamos que te comuniques con PPU a
            través de correo electrónico.
          </p>
          <a href="mailto:" style={{ textDecoration: "none" }}>
            <button
              style={{
                color: "#fff",
                backgroundColor: "rgba(0,0,0,0.2)",
                border: "none",
                borderRadius: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px 10px",
                fontSize: "12px",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Enviar correo
            </button>
          </a>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getListRisk = (risk: boolean, warning: boolean) => {
    if (risk) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="HIGH" />
        </div>
      );
    } else if (warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="MEDIUM" />
        </div>
      );
    } else if (!risk && !warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5 style={{ fontSize: "14px", fontWeight: 700, color: "white" }}>
            No
          </h5>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            No
          </h5>
          <AlertDot levelAlert="NONE" />
        </div>
      );
    }
  };

  const getServiceNameAlerts = (service: string) => {
    switch (service) {
      case "COREID":
        return "COREID";
      case "MAGICFORMS":
        return "MAGIC FORMS";
      case "FIRMA":
        return "FIRMA";
      case "HYPERFLOW":
        return "HYPERFLOW";
      default:
        return "";
    }
  };

  const getStatusNameAlerts = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "Activa";
      case "CLOSED":
        return "Cerrada";
      case "ACK":
        return "Revisada";
      default:
        return "Abierto";
    }
  };

  let getCategoryNameAlerts = (category: string) => {
    switch (category) {
      case "COMPLIANCE":
        return "COMPLIANCE";
      case "INCONGRUENCE":
        return "INCONGRUENCIAS";
      case "DATE":
        return "FECHAS";
      case "ATTACHMENT":
        return "ADJUNTOS";
      case "AUTHENTICITY":
        return "AUTENTICIDAD";
      default:
        return "";
    }
  };

  const getCriticidadAlertsRender = (criticidad: string) => {
    switch (criticidad) {
      case "HIGH":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Alta
            </h5>
            <AlertDot levelAlert="HIGH" />
          </div>
        );
      case "MEDIUM":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Media
            </h5>
            <AlertDot levelAlert="MEDIUM" />
          </div>
        );
      case "LOW":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );
      case "OTHER":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );

      default:
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Otra
            </h5>
            <AlertDot levelAlert="OTHER" />
          </div>
        );
    }
  };

  const getAsesorInfo = (asesorId: string) => {
    let asesor = asesores.find((asesor) => asesor.id === asesorId);
    if (asesor) {
      return (
        <a
          href={`https://forms.anteia.co/asesores?idAsesor=${asesor.id}`}
          target="_blank"
        >
          <div
            className="flex-col text-medium primary-color"
            style={{
              cursor: "pointer",
              background: "rgba(0,0,0,0.4)",
              width: "95%",
              borderRadius: "16px",
              padding: "2% 0 10px 3%",
            }}
          >
            <h5
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "white",
                textAlign: "center",
              }}
            >
              Asesor asociado
            </h5>

            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Nombre: <strong>{asesor.name}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Correo: <strong>{asesor.email}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Identificación : <strong>{asesor.identification}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Oficina : <strong>{asesor.asesorData.oficina}</strong>
            </h6>
            <h6
              style={{
                fontSize: "12px",
                fontWeight: 500,
                color: "white",
              }}
            >
              Cargo : <strong>{asesor.asesorData.cargo}</strong>
            </h6>
          </div>
        </a>
      );
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (id === "flowsrevision") {
      if (!modoRevision || !flows) {
        setmodoRevision(true);
        dispatch(getRevisionMode());
      }
    }
    if (hyperflowError === "") {
      if (
        (!currentFlow ||
          (id !== "flowsrevision" && currentFlow.id !== id) ||
          (id === "flowsrevision" && currentFlow.id !== idRevisionCurrent)) &&
        id !== ""
      ) {
        if (id === "flowsrevision") {
          if (flows && flows[index] && flows[index].id) {
            let cur = flows[index].id;
            setidRevisionCurrent(cur);
            dispatch(getFlowById(cur || ""));
          }
        } else {
          dispatch(getFlowById(id || ""));
        }
      }
    } else {
      if (hyperflowError === "403") {
        setTimeout(() => {
          navigate("/services/hyperFlow/flows");
        }, 4000);
      }
    }

    if (currentAttatchment.fileBase64 !== "") {
      let url = `data:application/pdf;base64,${currentAttatchment.fileBase64}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, currentAttatchment.fileName);
        });
      dispatch(emptyCurrentAttatchment());
    }

    if (currentFlow && loadingHyperflowAlerts === "idle") {
      dispatch(getHyperflowAlerts(currentFlow.id));
    }

    if (currentFlow && customStates === null) {
      dispatch(getCustomStates(id!));
    }

    if (currentFlow && currentFlow && loadingAttatchments === "idle") {
      dispatch(getAttatchmentByRegistrationId(currentFlow.userId));
    }
    if (currentFlow && !currentImages && loadingImages === "idle") {
      dispatch(
        getRegistrationImagesByRegistartionId(currentFlow.registration?.id!)
      );
    }
    if (currentFlow && !dataShowcase && loadingShowcase === "idle") {
      dispatch(getDataShowcaseById(currentFlow.id));
    }
    if (currentFlow && loadingManualInputData === "idle") {
      dispatch(getManualInputData());
    }
    if (currentFlow && rpaStatusLoading === "idle") {
      dispatch(getRpaStatus());
    }
    if (
      currentFlow &&
      currentFlow?.registrationAlerts &&
      currentFlow?.registrationAlerts.alerts &&
      !servicealertsn
    ) {
      let temp = servicealerts;
      for (let i = 0; i < currentFlow?.registrationAlerts.alerts.length; i++) {
        if (!temp.includes(currentFlow?.registrationAlerts.alerts[i].service)) {
          temp.push(currentFlow?.registrationAlerts.alerts[i].service);
        }
      }
      setservicealerts(temp);
      setservicealertsn(true);
    }

    dispatch(setIsFlowDetail(true));

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentEntity !== id && id) {
      dispatch(getCurrentTagsByEntity({ entityId: id, service: "HYPERFLOW" }));
    }

    if (loadingHyperFlow == "idle") {
      dispatch(getAsesores());
    }

    if (!currentSupportFiles && currentFlow) {
      dispatch(getSupportFiles());
    }

    return () => {};
  }, [
    modoRevision,
    idRevisionCurrent,
    loadingHyperFlow,
    id,
    currentFlow,
    hyperflowError,
    currentAttatchment,
    loadingAlerts,
    loadingAttatchments,
    loadingImages,
    rpaStatusLoading,
  ]);

  const getTagsRender = () => {
    if (loadingEntityTags == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
            margin: "5px 0px",
          }}
        >
          {currentEntityTags.map((tag) => {
            return (
              <div
                className="flex-row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)",
                  borderRadius: "8px",
                  padding: "3px 8px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  {tag.tag}
                </div>
                {roles.includes("tags.edit") && (
                  <DeleteTag
                    onClick={() => {
                      dispatch(
                        disassociateTag({
                          tagId: tag.id,
                          entityId: id!,
                          service: "HYPERFLOW",
                        })
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            );
          })}
          <div>
            <div
              className="flex-row"
              style={{
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: "8px",
                padding: "1px 8px",
                marginRight: "5px",
                marginLeft: "0px",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (roles.includes("tags.edit")) {
                  setAddTagWindow(!addTagWindow);
                }
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 600,
                  color: "#fff",
                  marginRight: "5px",
                }}
              >
                Etiquetas
              </div>
              {roles.includes("tags.edit") && (
                <AddTag
                  style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
                />
              )}
            </div>
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: id!,
                              service: "HYPERFLOW",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag && tagCreation.tag.length > 0
                              ? "visible"
                              : "hidden",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: id!,
                                service: "HYPERFLOW",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: id!,
                                  service: "HYPERFLOW",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const valuesHasAlert = (elements: string[]): boolean => {
    if (elements.length === 0) return false;
    if (
      currentFlow &&
      currentFlow.registration &&
      currentFlow.registration.multiFactorMatrix &&
      currentFlow.registration.multiFactorMatrix.userDataConfirmation &&
      currentFlow.registration.multiFactorMatrix.userDataConfirmation.active
    ) {
      let hasAlerts = false;
      for (let element of elements) {
        let possibleAlert =
          currentFlow.registration.multiFactorMatrix.userDataConfirmation.alerts.find(
            (alert) => alert.tag.includes(element)
          );
        if (possibleAlert) {
          hasAlerts = true;
        }
      }

      if (hasAlerts) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const [currentSectionCoreId, setCurrentSectionCoreId] = useState<
    | "DOCUMENTO DE IDENTIDAD"
    | "BIOMETRÍA"
    | "ESTADO DEL REGISTRO"
    | "MATRIZ DE VERIFICACIÓN"
    | "LISTAS RESTRICTIVAS"
  >("DOCUMENTO DE IDENTIDAD");
  const [currentSectionMagic, setCurrentSectionMagic] = useState<
    "FORMULARIO" | "CAMPOS"
  >("FORMULARIO");
  const [currentSectionFirma, setCurrentSectionFirma] = useState<
    "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES"
  >("DOCUMENTOS");
  const [currentSectionPagare, setCurrentSectionPagare] = useState<
    "DOCUMENTOS" | "DEUDORES" | "HISTORIAL DEL PAGARE" | "OTROS DOCUMENTOS"
  >("DOCUMENTOS");
  const [currentSectionAdjuntos, setCurrentSectionAdjuntos] =
    useState<"ADJUNTOS">("ADJUNTOS");

  let nextIndex = () => {
    if (flows) {
      let new_index = 0;

      if (index < flows.length) {
        new_index = index + 1;
      } else {
        dispatch(changePageState(currentPage + 1));
        new_index = 0;
      }
      dispatch(changeIndex(new_index));
      setidRevisionCurrent(flows[new_index].id);
    }
  };

  const getCustomStateExpandible = (item: CustomStateItem) => {
    return (
      <div
        className="flex-row"
        style={{
          padding: "0px 5% 0px 5%",
          margin: "0px 0px 3% 0px",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div>
          <div
            className="flex-col"
            style={{
              background: "rgba(0,0,0,0.1)",
              borderRadius: "8px",
              padding: "5%",
              minWidth: "180px",
              maxWidth: "300px",
              justifyContent: "center",
            }}
          >
            <div className="flex-col">
              <div
                className="text-normal text-medium alert-color"
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid  rgba(255,255,255,0.5)",

                  textTransform: "uppercase",
                  marginBottom: "10px",
                }}
              >
                Estado Seleccionado
              </div>
            </div>

            <div
              className="flex-row"
              style={{
                width: "100%",
                alignContent: "center",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  alignContent: "center",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="text-medium text-bold primary-color"
                  style={{
                    marginTop: "auto",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    borderRadius: "8px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {item.selectedStateName}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-col"
          style={{
            justifyContent: "center",
            alignItems: "start",
            width: "50%",
          }}
        >
          <label
            htmlFor="comment"
            style={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                color: "#FFF",
                fontWeight: "700",
                textAlign: "start",
                margin: "0 0 2px 2px",
              }}
            >
              Comentario
            </p>
            <textarea
              readOnly
              rows={3}
              value={item.comment}
              style={{
                resize: "none",
                border: "0.5px solid rgba(255,255,255,0.2)",
                borderRadius: "6px",
                background: "rgba(255,255,255,0.05)",
                minHeight: "35px",
                fontSize: "13px",
                fontWeight: "600",
                textAlign: "start",
                width: "100%",
                color: "#FFF",
              }}
            />
          </label>
        </div>
      </div>
    );
  };

  const getStateComponent = () => {
    if (customStates && customStates.customStates && currentFlow) {
      let entriesFrom = Object.values(customStates.customStates);
      let buttons: Object | null = null;
      let title = "Definiendo estado";
      for (let entry of entriesFrom) {
        if (entry.stepId === currentFlow.currentStepId) {
          buttons = entry.buttons;
          title = entry.tabName;
        }
      }

      if (buttons) {
        return (
          <StateComponent
            title={title}
            loadingIndicator={loadingResolveState}
            stateOptions={buttons}
            eventChangeCustom={(data: { comment: string; state: string }) => {
              let req: ResolveCustomStateRequest = {
                comment: data.comment,
                selectedState: data.state,
                stepId: currentFlow?.currentStepId!,
              };
              dispatch(resolveCustomState(req));
              if (modoRevision) {
                nextIndex();
              }
            }}
            currentState={currentFlow ? currentFlow.flowStatus : ""}
            updateRe={id === "flowsrevision" ? idRevisionCurrent : id ? id : ""}
            setShowSupportFiles={setShowUploadFileModal}
          />
        );
      }
    }
    return (
      <StateComponent
        stepDescriptionId={
          currentFlow?.executedSteps?.[currentFlow.currentStepId]
            ?.stepDescriptionId ?? ""
        }
        eventChangeState={(data: { state: string; comment: string }) => {
          dispatch(
            changeStatusById({
              comment: data.comment,
              selectedState: data.state,
              stepId: "",
            })
          );
          if (modoRevision) {
            nextIndex();
          }
        }}
        currentState={currentFlow ? currentFlow.flowStatus : ""}
        updateRe={id === "flowsrevision" ? idRevisionCurrent : id ? id : ""}
        setShowSupportFiles={setShowUploadFileModal}
      />
    );
  };

  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm
  ) => {
    switch (loadingPDFStatus) {
      case "idle":
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={base64PDFToBlobUrl(filledForm.document!)}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <p style={{ color: "white", width: "80%", textAlign: "center" }}>
                Tu navegador no permite visualizar PDFs, puedes descargar el
                documento aquí{" "}
                <a
                  style={{ color: "white", textDecoration: "underline" }}
                  onClick={() => {
                    const url = `data:application/pdf;base64,${filledForm.document}`;
                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          filledForm.pdfName,
                          "application/pdf"
                        );
                      });
                  }}
                >
                  Descargar
                </a>
              </p>
            </div>
          </object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
    }
  };

  let getDocumentSign = (loadingPDFStatus: LoadingStatus, sign: File) => {
    switch (loadingPDFStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={base64PDFToBlobUrl(sign.documentBase64)}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <p style={{ color: "white", width: "80%", textAlign: "center" }}>
                Tu navegador no permite visualizar PDFs, puedes descargar el
                documento aquí{" "}
                <a
                  style={{ color: "white", textDecoration: "underline" }}
                  onClick={() => {
                    const url = `data:application/pdf;base64,${sign.documentBase64}`;
                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(
                          blob,
                          sign.documentName,
                          "application/pdf"
                        );
                      });
                  }}
                >
                  Descargar
                </a>
              </p>
            </div>
          </object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">Firma</div>
        );
    }
  };

  const getDetailData = (value: string) => {
    return (
      <div
        className="flex-col"
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className="text-medium text-bold primary-color-50"
          style={{ width: "145px", textAlign: "center", fontSize: "11px" }}
        >
          Score
        </div>
        <div className="text-huge text-bold primary-color">{value}</div>
      </div>
    );
  };

  const getMatrixDetailData = (value: MultiFactorMatrixData, name: string) => {
    return (
      <div
        className="flex-col"
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className="text-medium text-bold primary-color-50"
          style={{ width: "90%", textAlign: "center", fontSize: "11px" }}
        >
          {name}
        </div>
        <div className="text-huge text-bold primary-color">{value.score}%</div>
        <div
          className="text-medium text-bold primary-color-50"
          style={{
            width: "80%",
            textAlign: "center",
            fontSize: "11px",
            wordWrap: "break-word",
          }}
        >
          Umbral de bloqueo: {value.blockingThreshold}%
        </div>
      </div>
    );
  };

  const getDescriptionFromMatrixData = (value: MultiFactorMatrixData) => {
    if (value.alerts.length > 0) {
      return value.alerts.join("| ");
    } else {
      return "";
    }
  };

  const getMatrixAlert = (active: boolean, value?: MultiFactorMatrixData) => {
    if (!active) {
      return false;
    }
    if (!value) {
      return false;
    }
    if (value.alerts.length > 0) {
      return true;
    }
    if (!value.passes) {
      return true;
    }
    return false;
  };

  let getCurrentContentCoreId = () => {
    switch (currentSectionCoreId) {
      case "DOCUMENTO DE IDENTIDAD":
        return (
          currentFlow?.registration && (
            <div className="flex-row content-table">
              <div
                className="col-4 data-colum"
                style={{ padding: "20px", paddingBottom: "10px" }}
              >
                {getTextValue(
                  "Id del registro",
                  currentFlow?.registration.id || "No encontrado",
                  false
                )}
                {getTextValue(
                  "Fecha de nacimiento",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.birthDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Número de documento",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.documentNumber
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Fecha de expedición",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.expeditionDate
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Nombre y apellido",
                  currentFlow?.registration
                    ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                    : "No encontrado",
                  currentFlow.registration.multiFactorMatrix
                    ? currentFlow.registration.multiFactorMatrix?.veracity
                        .alerts.length > 0
                    : false
                )}
                {getTextValue(
                  "Lugar de nacimiento",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.birthPlace
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Sexo",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.gender
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Tipo de sangre (RH)",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.bloodType
                    : "No encontrado",
                  false
                )}
                {getTextValue(
                  "Lugar de expedición",
                  currentFlow?.registration
                    ? currentFlow.registration.userDocument.expeditionCity
                    : "No encontrado",
                  false
                )}
              </div>
              <div className="col-4 data-colum">
                <div
                  className="text-bold text-medium primary-color"
                  style={{ textAlign: "center" }}
                >
                  Documento
                  <br />
                </div>

                <Generalmages
                  images={currentImages}
                  loadingImages={loadingImages}
                />

                <div
                  className="text-medium text-normal primary-color"
                  style={{ padding: "10px", textAlign: "center" }}
                >
                  {`OCR: ${
                    currentFlow?.registration.unifiedChecks.ocrCheck.active
                      ? currentFlow?.registration.unifiedChecks.ocrCheck.result
                        ? "OK"
                        : "NO OK"
                      : "Desactivado"
                  }`}
                </div>
              </div>
              <div className="col-4 data-colum" style={{ padding: "10px" }}>
                {currentFlow?.registration.unifiedChecks.documentValidityCheck
                  .active ? (
                  <ExpandibleComponent
                    alert={getMatrixAlert(
                      currentFlow.registration.unifiedChecks
                        .documentValidityCheck.active,
                      currentFlow.registration.multiFactorMatrix?.validity
                    )}
                    description={
                      currentFlow.registration.unifiedChecks
                        .documentValidityCheck.description
                        ? currentFlow?.registration.unifiedChecks.documentValidityCheck.description.split(
                            "Screen"
                          )[0]
                        : ""
                    }
                    isActive={false}
                    detail={""}
                    title="Autenticidad de documento"
                    content={
                      <div className="flex-row">
                        <div
                          className="flex-col primary-color-35 pdd-left"
                          style={{ fontSize: "11px", fontWeight: "bold" }}
                        >
                          {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.validity.blockingThreshold}%`}
                        </div>
                        {getDetailData(
                          currentFlow?.registration.unifiedChecks
                            .documentValidityCheck.active
                            ? currentFlow?.registration.multiFactorMatrix
                              ? `${(currentFlow?.registration.multiFactorMatrix.validity.score).toFixed(
                                  1
                                )}%`
                              : ""
                            : "Desactivado"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
                {currentFlow.registration.multiFactorMatrix &&
                  currentFlow.registration.multiFactorMatrix.screen.active && (
                    <ExpandibleComponent
                      alert={
                        getMatrixAlert(
                          currentFlow.registration.multiFactorMatrix.screen
                            .active,
                          currentFlow.registration.multiFactorMatrix.screen
                        ) ||
                        getMatrixAlert(
                          currentFlow.registration.multiFactorMatrix.impresion
                            .active,
                          currentFlow.registration.multiFactorMatrix.impresion
                        )
                      }
                      description={`${getDescriptionFromMatrixData(
                        currentFlow.registration.multiFactorMatrix.screen
                      )} | ${getDescriptionFromMatrixData(
                        currentFlow.registration.multiFactorMatrix.impresion
                      )}`}
                      isActive={false}
                      detail={""}
                      title="Captura de documento"
                      content={
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          {getMatrixDetailData(
                            currentFlow?.registration.multiFactorMatrix.screen,
                            "Detección pantalla"
                          )}

                          {getMatrixDetailData(
                            currentFlow?.registration.multiFactorMatrix
                              .impresion,
                            "Detección impresión"
                          )}
                        </div>
                      }
                    />
                  )}
                {currentFlow.registration.multiFactorMatrix &&
                currentFlow.registration.multiFactorMatrix?.veracity ? (
                  <ExpandibleComponentList
                    alert={
                      getMatrixAlert(
                        currentFlow.registration.unifiedChecks
                          .governmentDataMatchCheck.active,
                        currentFlow.registration.multiFactorMatrix?.govEntity
                      ) ||
                      getMatrixAlert(
                        currentFlow.registration.unifiedChecks
                          .governmentDataMatchCheck.active,
                        currentFlow.registration.multiFactorMatrix?.veracity
                      )
                    }
                    alerts={
                      currentFlow.registration.multiFactorMatrix
                        ? currentFlow.registration.multiFactorMatrix?.veracity.alerts.concat(
                            currentFlow.registration.multiFactorMatrix
                              ?.govEntity.alerts
                          )
                        : []
                    }
                    isActive={false}
                    title="Match Gubernamental"
                    content={
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        {getMatrixDetailData(
                          currentFlow?.registration.multiFactorMatrix.veracity,
                          "Coincidencia de datos"
                        )}

                        {getMatrixDetailData(
                          currentFlow?.registration.multiFactorMatrix.govEntity,
                          "Alertas gubernamentales"
                        )}
                      </div>
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )
        );
      case "BIOMETRÍA":
        return currentFlow?.registration ? (
          <div
            className="flex-row content-table"
            style={{ width: "90%", margin: "10px" }}
          >
            <div className="col-6">
              <BiometryImages
                images={currentImages}
                loadingImages={loadingImages}
              />

              <div />
            </div>
            <div className="col-6" style={{ padding: "2%", marginLeft: "10%" }}>
              <ExpandibleComponent
                alert={getMatrixAlert(
                  currentFlow.registration.unifiedChecks.facematchCheck.active,
                  currentFlow.registration.multiFactorMatrix?.faceMatch
                )}
                description={
                  currentFlow.registration.unifiedChecks.facematchCheck
                    .description
                    ? currentFlow?.registration.unifiedChecks.facematchCheck
                        .description
                    : ""
                }
                isActive={false}
                detail={""}
                title="Facematch"
                content={
                  <div
                    className="flex-row"
                    style={{ padding: "0 0 0 5px", alignItems: "center" }}
                  >
                    <div
                      className="flex-col primary-color-50 pdd-left"
                      style={{
                        textAlign: "center",
                        justifySelf: "center",
                        padding: "1%",
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.faceMatch.blockingThreshold}%`}
                    </div>
                    {getDetailData(
                      currentFlow?.registration.unifiedChecks.facematchCheck
                        .active
                        ? `${currentFlow?.registration.faceMatchPercent.toFixed(
                            1
                          )}%`
                        : "Desactivado"
                    )}
                  </div>
                }
              />

              <ExpandibleComponent
                alert={getMatrixAlert(
                  currentFlow.registration.multiFactorMatrix?.liveness.active ||
                    false,
                  currentFlow.registration.multiFactorMatrix?.liveness
                )}
                description={
                  currentFlow.registration.multiFactorMatrix?.liveness.active &&
                  currentFlow.registration.multiFactorMatrix?.liveness.alerts
                    .length > 0
                    ? currentFlow.registration.multiFactorMatrix?.liveness.alerts.join(
                        "| "
                      )
                    : ""
                }
                isActive={false}
                detail={""}
                title="Liveness"
                content={
                  <div
                    className="flex-row"
                    style={{ padding: "0 0 0 5px", alignItems: "center" }}
                  >
                    <div
                      className="flex-col primary-color-50 pdd-left"
                      style={{
                        textAlign: "center",
                        justifySelf: "center",
                        padding: "1%",
                        fontSize: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      {`Umbral de Bloqueo: ${currentFlow?.registration.multiFactorMatrix?.liveness.blockingThreshold}%`}
                    </div>
                    {getDetailData(
                      currentFlow.registration.multiFactorMatrix?.liveness
                        .active
                        ? `${currentFlow?.registration.multiFactorMatrix.liveness.score.toFixed(
                            1
                          )}%`
                        : "Desactivado"
                    )}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case "ESTADO DEL REGISTRO":
        return currentFlow?.registration ? (
          <div className="flex-row content-table" style={{ width: "100%" }}>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.listsCheck.description
                }
                isActive={false}
                detail={""}
                title="Listas"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.listsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.gpsCheck.description
                }
                isActive={false}
                detail={""}
                title="GPS"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.gpsCheck.result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
            <div className="col-4" style={{ padding: "2%" }}>
              <ExpandibleComponent
                alert={false}
                description={
                  currentFlow?.registration.unifiedChecks.deviceIdCheck
                    .description
                }
                isActive={false}
                detail={""}
                title="Device ID"
                content={
                  <div
                    className="flex-row primary-color-40"
                    style={{ padding: "10%" }}
                  >
                    {currentFlow?.registration.unifiedChecks.deviceIdCheck
                      .result
                      ? "OK"
                      : "NO OK"}
                  </div>
                }
              />
            </div>
          </div>
        ) : (
          <></>
        );
      case "MATRIZ DE VERIFICACIÓN":
        return (
          currentFlow?.registration && (
            <div
              className="flex-row content-table text-normal text-small"
              style={{ width: "100%" }}
            >
              <div className="flex-col" style={{ width: "30%" }}>
                <div
                  className="flex-col primary-color"
                  style={{
                    width: "70%",
                    borderRadius: "8px",
                    background: "rgba(0,0,0,0.3)",
                    padding: "3%",
                    marginLeft: "10px",
                    marginTop: "10%",
                  }}
                >
                  {currentFlow.registration.multiFactorMatrix?.minScore && (
                    <div className="flex-row" style={{ paddingLeft: "20px" }}>
                      {`Umbral: ${currentFlow.registration.multiFactorMatrix?.minScore}%`}
                    </div>
                  )}
                  {currentFlow.registration.multiFactorMatrix?.finalScore && (
                    <div className="flex-row" style={{ paddingLeft: "20px" }}>
                      {`Score Total: ${currentFlow.registration.multiFactorMatrix?.finalScore.toFixed(
                        2
                      )}%`}
                    </div>
                  )}
                </div>

                <table
                  style={{
                    width: "90%",
                    borderCollapse: "collapse",
                    margin: "10px",
                    overflow: "hidden",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <thead
                    className="text-normal text-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OCR"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.ocrCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"OTP"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.otpCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Correo"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.emailCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>
                        {"Dispositivos registrados"}
                      </td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.deviceIdCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Localización"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.gpsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ paddingLeft: "20px" }}>{"Listas"}</td>
                      <td className="p-1">
                        {currentFlow.registration.unifiedChecks.listsCheck
                          ? "OK"
                          : "NO OK"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="flex-col"
                style={{ width: "70%", height: "100%" }}
              >
                <table
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    borderCollapse: "collapse",
                    overflow: "hidden",

                    borderRadius: "12px",
                    padding: "10px",
                  }}
                >
                  <thead
                    className="text-normal text-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th className="p-1">Factor</th>
                      <th className="p-1">Score (0-100)</th>
                      <th className="p-1">Umbral de Bloqueo</th>
                      <th className="p-1">Admisión</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small primary-color-30`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                      margin: "5px 0 5px 0",
                    }}
                  >
                    <tr></tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Face match"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.faceMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.faceMatch
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Detección de impresiones en documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.impresion.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.impresion.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.impresion
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Detección de pantalla para documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.screen
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.screen
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.screen
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Validez-Autenticidad"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.validity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.validity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Veracidad documento"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.veracity
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.veracity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Listas"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.lists
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.lists
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Liveness"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.liveness
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.liveness
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.liveness
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>{" "}
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"GPS (Geofencing)"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.geolocation.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.geolocation.passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Device ID"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix?.deviceId
                            .blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.deviceId
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Match datos iniciales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.initialDataMatch.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix
                          ?.initialDataMatch.passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr className="p-1">
                      <td></td>
                      <td>{"Alertas entidades gubernamentales"}</td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.score
                        }
                      </td>
                      <td>
                        {
                          currentFlow?.registration?.multiFactorMatrix
                            ?.govEntity.blockingThreshold
                        }
                      </td>
                      <td>
                        {currentFlow?.registration?.multiFactorMatrix?.govEntity
                          .passes
                          ? "SI"
                          : "NO"}
                      </td>
                      <td></td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          )
        );
      case "LISTAS RESTRICTIVAS":
        let listas = currentFlow?.registration?.externalQueryResults;
        let renderListas: ListsRenderResult = {
          lists: [],
          numConsultedLists: 0,
          numResultsWithRisk: 0,
          numResultsWithWarning: 0,
        };
        if (listas) {
          for (let listaItem of listas) {
            for (let list of listaItem.listResults) {
              if (list.risk) {
                renderListas.numResultsWithRisk++;
              }
              if (list.warning) {
                renderListas.numResultsWithWarning++;
              }
              if (list.risk || list.warning) {
                renderListas.lists.push(list);
              }
            }
          }
          for (let listaItem of listas) {
            for (let list of listaItem.listResults) {
              renderListas.numConsultedLists++;

              if (!list.risk && !list.warning) {
                renderListas.lists.push(list);
              }
            }
          }
        }
        if (
          currentFlow?.registration?.multiFactorMatrix?.lists?.active ===
            false ||
          !listas
        ) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ color: "white", fontSize: "18px", fontWeight: 700 }}>
                No se encontraron resultados
              </h5>
            </div>
          );
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              height: "100%",
              justifyContent: "start",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginBottom: "14px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{ color: "white", fontSize: "13px", fontWeight: 700 }}
                >
                  Listas consultadas
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: 700,
                      margin: "0 5px 0 0 ",
                    }}
                  >
                    {renderListas.numConsultedLists}
                  </h5>
                  {renderListas.numConsultedLists > 0 && (
                    <AlertDot levelAlert="NONE" />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{ color: "white", fontSize: "13px", fontWeight: 700 }}
                >
                  Listas con riesgo
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: 700,
                      margin: "0 5px 0 0 ",
                    }}
                  >
                    {renderListas.numResultsWithRisk}
                  </h5>
                  {renderListas.numResultsWithRisk > 0 && (
                    <AlertDot levelAlert="HIGH" />
                  )}
                </div>
              </div>
            </div>

            <div
              className="flex-col"
              style={{
                width: "100%",
                overflow: "auto",
                height: "70%",
                marginBottom: "14px",
              }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre lista</th>
                    <th>Tiene riesgo</th>
                    <th>Coincidencia por documento</th>
                    <th>Coincidencia por nombre</th>
                    <th>Detalle</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td></td>
                    <td style={{ width: "40%" }}></td>
                    <td></td> <td></td>
                    <td></td> <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {renderListas.lists.map((list, i) => {
                    return (
                      <tr
                        key={i}
                        className="list-hover"
                        style={{ height: "30px" }}
                      >
                        <td></td>
                        <td>{list.listName}</td>
                        <td>{getListRisk(list.risk, false)}</td>
                        <td>{list.documentMatch ? "Sí" : "No"}</td>
                        <td>{list.nameMatch ? "Sí" : "No"}</td>
                        <td>
                          {list.resultDetails.length > 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setListDetail(list.resultDetails);
                                setListDetailOpen(true);
                              }}
                              style={{
                                color: "#fff",
                                backgroundColor: "rgba(0,0,0,0.2)",
                                border: "none",
                                borderRadius: "8px",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "4px 10px",
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              Ver detalle
                            </button>
                          ) : (
                            <>No tiene</>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  let getCurrentContentMagic = () => {
    switch (currentSectionMagic) {
      case "FORMULARIO":
        return (
          currentSectionStep &&
          currentSectionStep?.detailMagic && (
            <div className="flex-row" style={{ height: "100%" }}>
              <div
                className="flex-col"
                style={{ width: "30%", padding: "0 5px 10px 20px" }}
              >
                <div
                  className="text-normal text-small primary-color-35"
                  style={{ wordBreak: "break-word" }}
                >
                  {` Nombre: ${
                    currentSectionStep && currentSectionStep?.detailMagic
                      ? currentSectionStep?.detailMagic.pdfName
                      : ""
                  }`}
                </div>
                <div className="text-normal text-small primary-color-35">
                  {` ID Documento: ${
                    currentSectionStep && currentSectionStep?.detailMagic
                      ? currentSectionStep?.detailMagic.id
                      : ""
                  }`}
                </div>
                <div style={{ marginTop: "auto", padding: "1%" }}>
                  <p
                    style={{
                      color: "rgba(255,255,255,0.5)",
                      fontSize: "11px",
                      marginBottom: "0",
                    }}
                  >
                    Si no puedes visualizarlo el documento, puedes descargarlo:
                  </p>
                  <button
                    className="flex-row text-small primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                      border: "none",
                      padding: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        currentSectionStep &&
                        currentSectionStep.detailMagic &&
                        currentSectionStep.detailMagic.document
                      ) {
                        let url = `data:application/pdf;base64,${currentSectionStep.detailMagic.document}`;

                        fetch(url)
                          .then((res) => res.blob())
                          .then((blob) => {
                            fileDownload(
                              blob,
                              currentSectionStep.detailMagic?.pdfName
                                ? currentSectionStep.detailMagic?.pdfName
                                : "Document.pdf"
                            );
                          });
                      }
                    }}
                  >
                    {"Descargar "}
                    <div style={{ padding: "2%" }}>
                      <DownloadIcon style={{ margin: "4px" }} />
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex-col" style={{ width: "70%" }}>
                {getDocument(loadingHyperFlow, currentSectionStep?.detailMagic)}
              </div>
            </div>
          )
        );
      case "CAMPOS":
        return (
          currentSectionStep &&
          currentSectionStep?.detailMagic && (
            <div
              className="flex-col primary-color-35"
              style={{ width: "100%", overflow: "auto", height: "250px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  background: "rgba(0,0,0,0.2)",
                  margin: "0 1% 1% 1%",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    width: "100%",
                    borderBottom: "1px rgba(255,255,255,0.4)",
                    background: "rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    className="text-normal text-medium"
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Información Solicitada
                  </div>
                  <div
                    className="text-normal text-medium"
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Respuesta
                  </div>
                  <div
                    className="text-normal text-medium"
                    style={{
                      display: "flex",
                      flex: "1",
                      padding: "8px 0",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Tipo de Campo
                  </div>
                </div>
                {currentSectionStep?.detailMagic.requirements.map((e) => {
                  let response =
                    currentSectionStep.detailMagic?.data[e.stitchTypeId];
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        width: "100%",
                        borderBottom: "1px rgba(255,255,255,0.4)",
                      }}
                    >
                      <div
                        className="text-normal text-x-small"
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {e.stitchTypeId}
                      </div>
                      <div
                        className="text-normal text-x-small"
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {response}
                      </div>
                      <div
                        className="text-normal text-x-small"
                        style={{
                          display: "flex",
                          flex: "1",
                          padding: "8px 4px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  let getCurrentContentManualRetry = () => {
    let showButton = false;
    if (currentFlow && currentFlow.executedSteps) {
      let currentStep2 = currentFlow?.executedSteps[currentFlow.currentStepId];
      if (currentStep2.stepDescriptionId === "MANUAL_RETRY") {
        showButton = true;
      }
    }
    if (showButton === true) {
      return (
        <div className="flex-row content-table">
          <div className="flex-col">
            <button
              className="flex-row text-small primary-color"
              style={{
                borderRadius: "8px",
                background: "rgba(0,0,0,0.4)",
                border: "none",
                height: "50px",
                textAlign: "center",
                padding: "18px 20px",
                marginLeft: "50px",
              }}
              onClick={() => {
                dispatch(retryStepManual(currentFlow?.id ?? ""));
              }}
            >
              {"Reintentar"}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex-row content-table text-bold primary-color">
          <div className="flex-col" style={{ marginLeft: "50px" }}>
            No se encuentra en esta etapa actualmente
          </div>
        </div>
      );
    }
  };

  const getCurrentContentListas = () => {
    if (currentFlow && currentFlow.executedSteps) {
      let checkListsStep = Object.values(currentFlow.executedSteps).find(
        (step) => step.stepDescriptionId === "CHECK_LISTS"
      );
      let results = checkListsStep?.detailLists?.results;
      let identificationOptions: { value: string; label: string }[] = [];
      if (results) {
        for (let result in results) {
          identificationOptions.push({ value: result, label: result });
        }
      }

      if (results) {
        let listasOfUser = listSelected;
        let renderListas: ListsRenderResult = {
          lists: [],
          numConsultedLists: 0,
          numResultsWithRisk: 0,
          numResultsWithWarning: 0,
        };
        if (listasOfUser) {
          for (let list of listasOfUser.listResults) {
            if (list.risk) {
              renderListas.numResultsWithRisk++;
            }
            if (list.warning) {
              renderListas.numResultsWithWarning++;
            }
            if (list.risk || list.warning) {
              renderListas.lists.push(list);
            }
          }

          for (let list of listasOfUser.listResults) {
            renderListas.numConsultedLists++;

            if (!list.risk && !list.warning) {
              renderListas.lists.push(list);
            }
          }
        }

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              height: "100%",
              justifyContent: "start",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <h5
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Listas por cada documento ingresado en el Formulario
              </h5>
              <p
                style={{
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 600,
                  margin: "10px 0 15px 0",
                }}
              >
                Seleccione en el siguiente desplegable el documento y/o nombre
                sobre el que desea visualizar las listas consultadas
              </p>
              <DropDown
                alignCenter
                maxContent
                key={"Identifications"}
                title="No. Documento - Nombre"
                options={identificationOptions}
                isClearable={false}
                onChange={async (e) => {
                  if (e) {
                    if (results) {
                      setListSelected(null);
                      //sleep for a second to let the state update
                      await new Promise((r) => setTimeout(r, 200));
                      setListSelected(results[e.value]);
                    }
                  }
                }}
              />
            </div>
            {listSelected ? (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginBottom: "14px",
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h5
                      style={{
                        color: "white",
                        fontSize: "13px",
                        fontWeight: 700,
                      }}
                    >
                      Listas consultadas
                    </h5>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <h5
                        style={{
                          color: "white",
                          fontSize: "20px",
                          fontWeight: 700,
                          margin: "0 5px 0 0 ",
                        }}
                      >
                        {renderListas.numConsultedLists}
                      </h5>
                      {renderListas.numConsultedLists > 0 && (
                        <AlertDot levelAlert="NONE" />
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h5
                      style={{
                        color: "white",
                        fontSize: "13px",
                        fontWeight: 700,
                      }}
                    >
                      Listas con riesgo
                    </h5>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <h5
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: 700,
                          margin: "0 5px 0 0 ",
                        }}
                      >
                        {renderListas.numResultsWithRisk}
                      </h5>
                      {renderListas.numResultsWithRisk > 0 && (
                        <AlertDot levelAlert="HIGH" />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="flex-col"
                  style={{
                    width: "100%",
                    overflow: "auto",
                    height: "70%",
                    marginBottom: "14px",
                  }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead
                      className="text-normal text-medium primary-color-35"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "30px",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th></th>
                        <th>Nombre lista</th>
                        <th>Tiene riesgo</th>
                        <th>Coincidencia por documento</th>
                        <th>Coincidencia por nombre</th>
                        <th>Detalle</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody
                      className="text-normal text-x-small primary-color"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <td></td>
                        <td style={{ width: "40%" }}></td>
                        <td></td> <td></td>
                        <td></td> <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {renderListas.lists.map((list, i) => {
                        return (
                          <tr
                            key={i}
                            className="list-hover"
                            style={{ height: "30px" }}
                          >
                            <td></td>
                            <td>{list.listName}</td>
                            <td>{getListRisk(list.risk, false)}</td>
                            <td>{list.documentMatch ? "Sí" : "No"}</td>
                            <td>{list.nameMatch ? "Sí" : "No"}</td>
                            <td>
                              {list.resultDetails.length > 0 ? (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setListDetail(list.resultDetails);
                                    setListDetailOpen(true);
                                  }}
                                  style={{
                                    color: "#fff",
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    border: "none",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "4px 10px",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Ver detalle
                                </button>
                              ) : (
                                <>No tiene</>
                              )}
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h5
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: 700,
                    textAlign: "center",
                    marginTop: "25px",
                  }}
                >
                  Aquí apareceran las listas de la persona o empresa
                  seleccionada
                </h5>
              </div>
            )}
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  const [currentSection, setCurrentSection] = useState<string>("");
  const [currentSectionStep, setCurrentSectionStep] =
    useState<ExecutedStep | null>(null);

  let getCurrentSectionContent = (name: string) => {
    switch (name) {
      case "CORE_ID":
        return getCurrentContentCoreId();
      case "MAGIC_FORMS":
        return getCurrentContentMagic();
      case "ELECTRONIC_SIGNATURE":
        return getCurrentContentFirma();
      case "PAGARE":
        return getCurrentContentPagare();
      case "MANUAL_RETRY":
        return getCurrentContentManualRetry();
      case "CHECK_LISTS":
        return getCurrentContentListas();
    }
  };

  let getTitle = (name: string, execStep: ExecutedStep) => {
    switch (name) {
      case "CORE_ID":
        return "Core ID";
      case "MAGIC_FORMS":
        return "Magic Forms";
      case "ELECTRONIC_SIGNATURE":
        return "Firmas";
      case "PAGARE":
        return "Pagaré";
      case "CHECK_LISTS":
        return "Listas restrictivas";
      case "MANUAL_RETRY":
        return execStep.customName;
      default:
        return "";
    }
  };
  let getCurrentContentFirma = () => {
    switch (currentSectionFirma) {
      case "DOCUMENTOS":
        return (
          currentSectionStep &&
          currentSectionStep?.detailSig && (
            <div className="flex-row" style={{ height: "100%" }}>
              <div
                className="flex-col"
                style={{ width: "30%", padding: "0 0 10px 20px" }}
              >
                <div className="text-normal text-small primary-color-35">
                  {` ID Documento: ${currentSectionStep?.detailSig.documentId}`}
                </div>
                <div className="text-normal text-small primary-color-35">
                  {` Estado actual: ${getStatusName(
                    currentSectionStep?.detailSig.status
                  )}`}
                </div>
                {currentSectionStep?.detailSig.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificado
                          ? "rgba(0,0,0,0.2)"
                          : "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificado(false);
                      }}
                    >
                      {"Documento Firmado"}
                    </button>
                  </div>
                )}
                {currentSectionStep?.detailSig.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      style={{
                        borderRadius: "8px",
                        background: certificado
                          ? "rgba(0,0,0,0.4)"
                          : "rgba(0,0,0,0.2)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                      onClick={() => {
                        setCertificado(true);
                      }}
                    >
                      {"Certificado de Firma"}
                    </button>
                  </div>
                )}
                {currentSectionStep?.detailSig.status === "Signed" && (
                  <div style={{ marginTop: "auto", padding: "1%" }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          currentSectionStep &&
                          currentSectionStep.detailSig &&
                          currentSectionStep.detailSig.documents &&
                          currentSectionStep.detailSig.documents
                            .finishedSignature
                        ) {
                          let url = `data:application/pdf;base64,${
                            certificado
                              ? currentSectionStep?.detailSig.documents
                                  ?.certificate.documentBase64
                              : currentSectionStep?.detailSig.documents
                                  ?.document.documentBase64
                          }`;

                          fetch(url)
                            .then((res) => res.blob())
                            .then((blob) => {
                              fileDownload(blob, "Certificado.pdf");
                            });
                        }
                      }}
                      className="flex-row text-small primary-color"
                      style={{
                        borderRadius: "8px",
                        background: "rgba(0,0,0,0.4)",
                        border: "none",
                        fontSize: "12px",
                        color: "white",
                        padding: "8px 16px",
                      }}
                    >
                      {"Descargar "}
                      <div style={{ padding: "2%" }}>
                        <DownloadIcon style={{ cursor: "pointer" }} />
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {currentSectionStep?.detailSig.status === "Signed" ? (
                <div className="flex-col" style={{ width: "70%" }}>
                  {currentSectionStep &&
                    currentSectionStep.detailSig &&
                    currentSectionStep.detailSig.documents &&
                    currentSectionStep.detailSig.documents.finishedSignature &&
                    getDocumentSign(
                      loadingHyperFlow,
                      certificado
                        ? currentSectionStep?.detailSig.documents?.certificate
                        : currentSectionStep?.detailSig.documents?.document
                    )}
                </div>
              ) : (
                <div
                  className="flex-col"
                  style={{
                    width: "70%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ color: "white" }}>
                    El documento no ha sido firmado
                  </h5>
                </div>
              )}
            </div>
          )
        );
      case "FIRMANTES":
        return (
          currentSectionStep &&
          currentSectionStep?.detailSig && (
            <div
              className="flex-col primary-color-35"
              style={{ width: "100%", height: "100%" }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Nombre y Apellido</th>
                    <th>Fecha de la Firma</th>
                    <th>User ID</th>
                    <th>Estado</th>
                    <th>Método</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    fontSize: "13px",
                    height: "30px",
                  }}
                >
                  {currentSectionStep?.detailSig.signers.map((e) => {
                    return (
                      <tr className="list-hover">
                        <td>{`${e.name} ${e.lastName}`}</td>
                        <td>{formatDateStandar(e.startedDate)}</td>
                        <td>{e.userId}</td>
                        <td>{e.status}</td>
                        <td>{e.validationMethod}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );
      case "HISTORIAL DE ACCIONES":
        return (
          currentSectionStep &&
          currentSectionStep?.detailSig && (
            <div
              className="flex-col primary-color-35"
              style={{
                width: "100%",
                padding: "0 10px 0 10px",
                height: "100%",
              }}
            >
              <div className="text-normal text-medium primary-color">
                Historial del Documento
              </div>
              <table
                style={{
                  margin: "1% 0 0 0",
                  width: "100%",

                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className="text-normal text-medium primary-color"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>Acción</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    height: "30px",
                    fontSize: "12px",
                  }}
                >
                  {currentSectionStep?.detailSig.history &&
                    currentSectionStep?.detailSig.history.map((e) => {
                      return (
                        <tr className="list-hover">
                          <td>{e.action}</td>
                          <td>{formatDateStandar(e.date)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  let getCurrentContentPagare = () => {
    switch (currentSectionPagare) {
      case "DOCUMENTOS":
        return (
          currentSectionStep &&
          currentSectionStep?.detailPagare && (
            <div className="flex-row" style={{ height: "100%" }}>
              <div
                className="flex-col"
                style={{ width: "30%", padding: "0 0 5px 20px" }}
              >
                <div
                  className="text-normal text-small primary-color-35"
                  style={{ wordBreak: "break-word" }}
                >
                  {`Id del pagare: ${currentSectionStep?.detailPagare?.id}`}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      background: "rgba(0,0,0,0.4)",
                      color: "white",
                      fontSize: "13px",
                      fontWeight: 600,
                      padding: "6px",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    Estado Actual
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                      background: "rgba(0,0,0,0.2)",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    {getStatusPagareName(
                      currentSectionStep?.detailPagare.status
                    )}
                  </div>
                </div>
                {currentSectionStep?.detailPagare.status &&
                  currentSectionStep?.detailPagare.status !== "Created" && (
                    <div style={{ marginTop: "auto", padding: "1%" }}>
                      <button
                        style={{
                          borderRadius: "8px",
                          background: cartaInstruccionesShow
                            ? "rgba(0,0,0,0.2)"
                            : "rgba(0,0,0,0.4)",
                          border: "none",
                          fontSize: "12px",
                          color: "white",
                          padding: "8px 16px",
                        }}
                        onClick={() => {
                          setCartaInstruccionesShow(false);
                        }}
                      >
                        {"Pagaré"}
                      </button>
                    </div>
                  )}
                {currentSectionStep?.detailPagare.type === "Blank" &&
                  currentSectionStep.detailPagare.documents?.carta
                    .documentBase64 && (
                    <div style={{ marginTop: "auto", padding: "1%" }}>
                      <button
                        style={{
                          borderRadius: "8px",
                          background: cartaInstruccionesShow
                            ? "rgba(0,0,0,0.4)"
                            : "rgba(0,0,0,0.2)",
                          border: "none",
                          fontSize: "12px",
                          color: "white",
                          padding: "8px 16px",
                        }}
                        onClick={() => {
                          setCartaInstruccionesShow(true);
                        }}
                      >
                        {"Carta de instrucciones"}
                      </button>
                    </div>
                  )}
                {currentSectionStep &&
                  currentSectionStep.detailPagare &&
                  currentSectionStep.detailPagare.status !== "Created" && (
                    <div style={{ marginTop: "auto", padding: "1%" }}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          let pagareName = "";
                          if (
                            currentSectionStep &&
                            currentSectionStep.detailPagare &&
                            currentSectionStep.detailPagare.documents &&
                            currentSectionStep.detailPagare.documents.pagare
                              .documentName
                          ) {
                            pagareName =
                              currentSectionStep.detailPagare.documents.pagare
                                .documentName;
                          }
                          let cartaName = "";
                          if (
                            currentSectionStep &&
                            currentSectionStep.detailPagare &&
                            currentSectionStep.detailPagare.documents &&
                            currentSectionStep.detailPagare.documents.carta &&
                            currentSectionStep.detailPagare.documents.carta
                              .documentName
                          ) {
                            cartaName =
                              currentSectionStep?.detailPagare.documents.carta
                                .documentName;
                          }
                          if (
                            currentSectionStep &&
                            currentSectionStep.detailPagare &&
                            currentSectionStep.detailPagare.documents &&
                            currentSectionStep.detailPagare.documents.pagare &&
                            currentSectionStep.detailPagare.documents.pagare
                              .documentBase64
                          ) {
                            let url = `data:application/pdf;base64,${
                              cartaInstruccionesShow
                                ? currentSectionStep?.detailPagare.documents
                                    .carta.documentBase64
                                : currentSectionStep?.detailPagare.documents
                                    ?.pagare.documentBase64
                            }`;

                            fetch(url)
                              .then((res) => res.blob())
                              .then((blob) => {
                                fileDownload(
                                  blob,
                                  cartaInstruccionesShow
                                    ? cartaName
                                    : pagareName
                                );
                              });
                          }
                        }}
                        className="flex-row "
                        style={{
                          borderRadius: "8px",
                          background: "rgba(0,0,0,0.4)",
                          border: "none",
                          fontSize: "12px",
                          color: "white",
                          padding: "8px 16px",
                        }}
                      >
                        {"Descargar "}
                        <div style={{ padding: "2%" }}>
                          <DownloadIcon style={{ cursor: "pointer" }} />
                        </div>
                      </button>
                    </div>
                  )}
              </div>
              <div className="flex-col" style={{ width: "70%" }}>
                {currentSectionStep?.detailPagare.status &&
                  currentSectionStep?.detailPagare.status !== "Created" &&
                  currentSectionStep &&
                  currentSectionStep.detailPagare &&
                  currentSectionStep.detailPagare.documents &&
                  currentSectionStep.detailPagare.documents.pagare &&
                  getDocumentSign(
                    loadingHyperFlow,
                    cartaInstruccionesShow
                      ? currentSectionStep?.detailPagare.documents?.carta
                      : currentSectionStep?.detailPagare.documents?.pagare
                  )}
              </div>
            </div>
          )
        );
      case "DEUDORES":
        return (
          currentSectionStep &&
          currentSectionStep?.detailPagare && (
            <div
              className="flex-col primary-color-35"
              style={{
                width: "100%",
                height: "100%",
                padding: "0 20px 5px 20px",
                overflow: "auto",
              }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th>User ID</th>
                    <th>Nombre y Apellido</th>

                    <th>No. Documento</th>
                    <th>Email</th>
                    <th>Celular</th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                    height: "30px",
                  }}
                >
                  {currentSectionStep?.detailPagare.deudores.map((e) => {
                    return (
                      <tr className="list-hover">
                        <td>{e.userId}</td>
                        <td>{`${e.nombre} ${e.apellido}`}</td>
                        <td>{e.identification}</td>
                        <td>{e.email}</td>
                        <td>{e.phoneNumber}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        );
      case "HISTORIAL DEL PAGARE":
        return (
          currentSectionStep &&
          currentSectionStep?.detailPagare && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "30%",
                  height: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  padding: "0px 0 10px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      background: "rgba(0,0,0,0.4)",
                      color: "white",
                      fontSize: "13px",
                      fontWeight: 600,
                      padding: "6px",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    Estado Actual
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                      background: "rgba(0,0,0,0.2)",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    {getStatusPagareName(
                      currentSectionStep?.detailPagare.status
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      background: "rgba(0,0,0,0.4)",
                      color: "white",
                      fontSize: "13px",
                      fontWeight: 600,
                      padding: "6px",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    Tipo de pagaré
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                      background: "rgba(0,0,0,0.2)",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    {currentSectionStep?.detailPagare.type === "Blank"
                      ? "En Blanco con carta de instrucciones"
                      : "Cerrado"}
                  </div>
                </div>
                <a
                  href={`/services/pagares/list/${currentSectionStep.detailPagare.id}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      padding: "12px 20px",
                      fontSize: "14px",
                      color: "white",
                      fontWeight: 600,
                      border: "none",
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.4)",
                    }}
                  >
                    Ver detalle <ExternalIcon />
                  </button>
                </a>
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  De click en este botón para ver más detalles
                </p>
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: 600,
                    margin: 0,
                  }}
                >
                  Allí podrá realizar otras acciones como endosos y
                  diligenciamientos
                </p>
              </div>
              <div
                className="flex-col primary-color-35"
                style={{
                  width: "60%",
                  height: "100%",
                  padding: "0 10px 0 10px",
                }}
              >
                <div
                  style={{
                    background: "rgba(0,0,0,0.6)",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rbga(255,255,255,0.9)",
                    fontSize: "15px",
                    fontWeight: 500,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  Historial Pagaré
                </div>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th>Acción</th>
                      <th>Fecha</th>
                      <th>Descripción </th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    {currentSectionStep?.detailPagare.ledgerAnnotations &&
                      currentSectionStep?.detailPagare.ledgerAnnotations.map(
                        (e) => {
                          return (
                            <tr
                              className="list-hover"
                              style={{ height: "30px" }}
                            >
                              <td>{getStatusPagareName(e.action)}</td>
                              <td>{formatDateStandar(e.fecha)}</td>
                              <td>{e.descripcion}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          )
        );
      case "OTROS DOCUMENTOS":
        return (
          currentSectionStep?.detailPagare?.documents?.extraDocuments && (
            <div style={{ width: "100%", height: "100%", padding: "10px" }}>
              <div
                className="flex-col"
                style={{
                  background: "rgba(0,0,0,0.4)",
                  height: "29px",
                  textAlignLast: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  borderRadius: "8px 8px 0 0 ",
                }}
              >
                <div className="text-normal text-medium primary-color-35">
                  Otros documentos
                </div>
              </div>
              <div
                className="flex-col"
                style={{
                  background: "rgba(0,0,0,0.2)",
                  paddingTop: "10px",
                  borderRadius: "0 0 8px 8px",
                  boxSizing: "border-box",

                  paddingBottom: "1%",
                }}
              >
                {currentSectionStep?.detailPagare.status &&
                currentSectionStep?.detailPagare.status !== "Created" ? (
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead
                      className="text-normal text-medium primary-color-35"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "30px",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th></th>
                        <th>Nombre</th>
                        <th>Descargar</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody
                      className="text-normal text-x-small primary-color"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {currentSectionStep.detailPagare.documents.extraDocuments.map(
                        (extraDocument, i) => {
                          return (
                            <tr key={i} style={{ height: "30px" }}>
                              <td></td>
                              <td style={{ textTransform: "uppercase" }}>
                                {extraDocument.name || ""}
                              </td>

                              <td>
                                {downloadingPagareExtraDocs[i] === "pending" ? (
                                  <LoadingOverlay
                                    active={
                                      downloadingPagareExtraDocs[i] ===
                                      "pending"
                                    }
                                    spinner={
                                      <ClipLoader color="white" size="20px" />
                                    }
                                  />
                                ) : (
                                  <DownArrow
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      downloadAtatchmentPagares(
                                        extraDocument.fileName,
                                        i
                                      );
                                    }}
                                  />
                                )}
                              </td>
                              <td></td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: 700,
                        textAlign: "center",
                        marginTop: "25px",
                      }}
                    >
                      No se han terminado de firmar los documentos extra
                    </h5>
                  </div>
                )}
              </div>
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  let getTextValueName = (name: string): string[] => {
    let nameList: string[] = [];
    if (name.includes("documento")) {
      nameList.push("documentNumber");
    } else if (name.includes("Nombre")) {
      nameList.push("firstName");
      nameList.push("lastName");
    } else if (name.includes("Fecha de expedición")) {
      nameList.push("expeditionDate");
    } else if (name.includes("Lugar de expedición")) {
      nameList.push("expeditionCity");
    } else if (name.includes("Sexo")) {
      nameList.push("gender");
    } else if (name.includes("Tipo de sangre")) {
      nameList.push("bloodType");
    } else if (name.includes("Fecha de nacimiento")) {
      nameList.push("birthDate");
    } else if (name.includes("Lugar de nacimiento")) {
      nameList.push("birthPlace");
    } else {
      ("");
    }
    return nameList;
  };

  let getTextValue = (name: string, value: string, alert: boolean) => {
    let nameForAlert = getTextValueName(name);

    let hasAlert = valuesHasAlert(nameForAlert);
    return (
      <>
        <div
          className="flex-col"
          style={{ cursor: hasAlert ? "pointer" : "auto" }}
          onClick={(e) => {
            if (hasAlert) {
              setModalDataAlertsOpen(true);
            }
          }}
        >
          <div className="flex-col text-small primary-color-40">{name}</div>
          <div
            className={`flex-row text-small ${
              alert || hasAlert ? "text-bold alert-color" : "primary-color"
            }`}
          >
            <div className="col-2">
              {alert || hasAlert ? <HyperFlowAlert /> : ""}
            </div>
            <div className="col-10">{value}</div>
          </div>
        </div>
      </>
    );
  };
  let getAlert = (name: string) => {
    switch (name) {
      case "CAMPOS":
        return servicealerts.includes("MagicForms");
      case "DOCUMENTO DE IDENTIDAD":
        return servicealerts.includes("coreId");
      case "LISTAS RESTRICTIVAS":
        return servicealerts.includes("lists");
    }

    return false;
  };
  let getMenuItemCoreId = (
    name:
      | "DOCUMENTO DE IDENTIDAD"
      | "BIOMETRÍA"
      | "ESTADO DEL REGISTRO"
      | "MATRIZ DE VERIFICACIÓN"
      | "LISTAS RESTRICTIVAS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionCoreId(name);
        }}
        style={{
          background:
            currentSectionCoreId === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionCoreId) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  let getMenuItemMagic = (
    name: "FORMULARIO" | "CAMPOS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionMagic(name);
        }}
        style={{
          background:
            currentSectionMagic === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionMagic) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  let getMenuItemFirmas = (
    name: "DOCUMENTOS" | "FIRMANTES" | "HISTORIAL DE ACCIONES",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionFirma(name);
        }}
        style={{
          background:
            currentSectionFirma === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionFirma) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  const getNotFoundError = () => {
    if (hyperflowError !== "") {
      switch (hyperflowError) {
        case "404":
          return "No se encontró el registro";
        case "403":
          return "No tienes este registro asignado, te vamos a redirigir a la lista de registros";
        case "500":
          return "Error interno del servidor";
        default:
          return "Error desconocido";
      }
    }
  };

  let getMenuItemPagares = (
    name:
      | "DOCUMENTOS"
      | "DEUDORES"
      | "HISTORIAL DEL PAGARE"
      | "OTROS DOCUMENTOS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionPagare(name);
        }}
        style={{
          background:
            currentSectionPagare === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionPagare) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  let getMenuItemAdjuntos = (
    name: "ADJUNTOS",
    first: boolean,
    last: boolean
  ) => {
    return (
      <div
        className="flex-row"
        onClick={() => {
          setCurrentSectionAdjuntos(name);
        }}
        style={{
          background:
            currentSectionAdjuntos === name
              ? "rgba(0,0,0,0.5)"
              : "rgba(0,0,0,0.4)",
          borderTopLeftRadius: first ? "8px" : "",
          borderTopRightRadius: first ? "8px" : "",
          borderBottomLeftRadius: last ? "8px" : "",
          borderBottomRightRadius: last ? "8px" : "",
          borderBottom: last ? "" : "1px solid rgba(255,255,255, 0.2)",
        }}
      >
        <div className="flex-col" style={{ width: "20%" }}>
          {getAlert(currentSectionAdjuntos) ? <HyperFlowAlert /> : ""}
        </div>
        <div className="flex-col" style={{ width: "80%", padding: "4%" }}>
          {name}
        </div>
      </div>
    );
  };

  let getCurrentSectionMenu = (name: string) => {
    let hasListsInCoreId = false;
    if (currentFlow?.registration?.multiFactorMatrix?.lists.active) {
      hasListsInCoreId = true;
    }
    switch (name) {
      case "CORE_ID":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "100%" }}
          >
            {getMenuItemCoreId("DOCUMENTO DE IDENTIDAD", true, false)}
            {getMenuItemCoreId("BIOMETRÍA", false, false)}
            {getMenuItemCoreId("ESTADO DEL REGISTRO", false, false)}
            {getMenuItemCoreId(
              "MATRIZ DE VERIFICACIÓN",
              false,
              !hasListsInCoreId
            )}
            {getMenuItemCoreId("LISTAS RESTRICTIVAS", false, hasListsInCoreId)}
          </div>
        );
      case "MAGIC_FORMS":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemMagic("FORMULARIO", true, false)}
            {getMenuItemMagic("CAMPOS", false, true)}
          </div>
        );
      case "ELECTRONIC_SIGNATURE":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemFirmas("DOCUMENTOS", true, false)}
            {getMenuItemFirmas("FIRMANTES", false, false)}
            {getMenuItemFirmas("HISTORIAL DE ACCIONES", false, true)}
          </div>
        );
      case "PAGARE":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemPagares("DOCUMENTOS", true, false)}
            {getMenuItemPagares("DEUDORES", false, false)}
            {getMenuItemPagares("HISTORIAL DEL PAGARE", false, false)}
            {getMenuItemPagares("OTROS DOCUMENTOS", false, true)}
          </div>
        );

      case "Adjuntos":
        return (
          <div
            className="flex-col text-bold text-medium primary-color"
            style={{ width: "80%" }}
          >
            {getMenuItemAdjuntos("ADJUNTOS", true, true)}
          </div>
        );
    }
  };

  let getRowInfoClient = (name: string, value: string) => {
    return (
      <div className="flex-row">
        <div className="flex-col" style={{ width: "50%" }}>
          {name}
        </div>
        <div className="flex-col" style={{ width: "50%" }}>
          {value}
        </div>
      </div>
    );
  };

  const [selectedDownload, setSelectedDownload] = useState(-1);

  let downloadAtatchment = (
    attatchment: Attatchment,
    selected: number,
    visualized?: boolean
  ) => {
    setSelectedDownload(selected);
    dispatch(
      getAttatchmentData({
        filledFormId: attatchment.filledFormId,
        stitchTypeId: attatchment.stitchTypeId,
        visualized: visualized,
      })
    );
  };

  let showFlowStatus = (flow: HyperFlow) => {
    let executableSteps: ExecutedStep[] = [];
    if (flow.executedSteps) {
      for (let stepId in flow.executedSteps) {
        let step = flow.executedSteps[stepId];
        if (step && step.reExecutable === true && step.status !== "PENDING") {
          // if (step) {
          executableSteps.push(step);
        }
      }
    }
    if (flow.executedSteps) {
      return (
        <div>
          <div
            className="flex-col text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
            }}
          >
            <div className="flex-row">
              Paso actual:{" "}
              {flow.executedSteps &&
              flow.currentStepId &&
              flow.executedSteps[flow.currentStepId]
                ? flow.executedSteps[flow.currentStepId].customName
                : flow.currentStepId}
            </div>
          </div>
          <div
            className="flex-col text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
            }}
          >
            <div className="flex-col" style={{ paddingBottom: "10px" }}>
              Ejecutar pasos manualmente
            </div>
            <div className="flex-col">
              {executableSteps.map((step) => {
                return (
                  <div key={step.id}>
                    <button
                      style={{
                        color: "#fff",
                        backgroundColor: "rgba(0,0,0,0.2)",
                        border: "none",
                        borderRadius: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4px 10px",
                        fontSize: "12px",
                        fontWeight: 700,
                        marginBottom: "10px",
                      }}
                      onClick={() => {
                        dispatch(
                          reExecuteStep({
                            execFlowId: flow.id,
                            stepId: step.id,
                          })
                        );
                      }}
                    >
                      {step.customName ?? step.id}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };

  let showSupportFiles = (supportFiles?: SupportFile[]) => {
    return (
      <div
        className="flex-col text-medium primary-color"
        style={{
          background: "rgba(0,0,0,0.2)",
          paddingTop: "10px",
          margin: "15px",
          borderRadius: "8px",
          boxSizing: "border-box",
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: "5%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h4 style={{ color: "#fff" }}> Lista de soportes</h4>
        <Modal
          isOpen={showUploadFileModal}
          style={customStylesModal}
          contentLabel="Modificar opciones"
          onRequestClose={() => {
            setShowUploadFileModal(false);
          }}
        >
          <UploadSupportFileComponent
            upload={uploadFileAsync}
            id={currentFlow?.id!}
          />
        </Modal>
        {downloadingSupportFiles === "pending" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ color: "#fff" }}>Cargando adjuntos</h3>
            <ClipLoader color={"#fff"} loading={true} size={50} />
          </div>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead
              className="text-normal text-medium primary-color-35"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>
                <th></th>
                <th style={{ width: "400px" }}>Nombre o descripción</th>
                <th>Nombre del archivo</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody
              className="text-normal text-x-small primary-color"
              style={{
                textAlign: "center",
              }}
            >
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {supportFiles &&
                supportFiles.length > 0 &&
                supportFiles.map((supportFile, i) => {
                  return (
                    <tr key={"r" + supportFile.name} style={{ height: "30px" }}>
                      <td></td>
                      <td style={{ textTransform: "uppercase" }}>
                        {supportFile.name || ""}
                      </td>
                      <td>{supportFile.fileName || ""}</td>
                      <td>
                        <button
                          className="boton1"
                          onClick={() =>
                            getSupportFileAPI(
                              currentFlow?.id!,
                              supportFile.fileName
                            )
                          }
                        >
                          Descargar
                        </button>
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            alignContent: "center",
          }}
        >
          <h4 style={{ color: "#fff" }}> Cargar nuevo soporte</h4>
          <input
            id="UploadFileButton"
            style={{ display: "none" }}
            type="file"
            onChange={(e) => {
              let file2 = e.target.files?.[0];
              if (file2) {
                setSupportFileName(file2.name);
                setSupportFile(file2);
              }
            }}
          ></input>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5
              style={{
                color: "#fff",
                marginTop: "20px",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              Nombre o descripción del soporte
            </h5>
            <input
              placeholder="Ej. Soporte de prueba"
              style={{
                height: "28px",
                width: "100%",
                background: "rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                color: "white",
                border: "none",
                padding: "9px 8px",
                fontSize: "16px",
                fontWeight: 600,
              }}
              value={supportName}
              onChange={(e) => {
                //Regex to validate is only text and numbers
                if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                  setSupportName(e.target.value);
                }
              }}
            />
          </div>
          <button
            className="boton1"
            style={{ marginTop: "20px" }}
            onClick={() => {
              document.getElementById("UploadFileButton")?.click();
            }}
          >
            Seleccionar archivo
          </button>
          {supportFile && (
            <h1
              className="text-medium"
              style={{ marginLeft: "10px", marginTop: "20px" }}
            >
              Archivo seleccionado:
            </h1>
          )}
          <h3 style={{ marginLeft: "10px", fontSize: "14px" }}>
            {supportFileName}
          </h3>

          <button
            className="boton1"
            style={{ marginTop: "20px" }}
            onClick={() => {
              if (supportFileName === "" || supportFile === null) {
                alert("Por favor seleccione un archivo");
              } else {
                if (supportName === "") {
                  alert("Por favor ingrese un nombre o descripción al soporte");
                } else {
                  uploadFileAsync(
                    supportFile!!,
                    currentFlow?.id!!,
                    supportFileName,
                    supportName
                  );
                }
              }
            }}
          >
            Subir archivo
          </button>
        </div>
      </div>
    );
  };

  const uploadFileAsync = async (
    file: globalThis.File,
    id: string,
    fileName: string,
    name: string
  ) => {
    let b64 = await toBase64(file);
    let b64_2 = b64.split(",")[1];
    let req = {
      fileBase64: b64_2,
      fileName: fileName,
      name: name,
    };
    let res = await uploadSupportFileAPI(req, id);
    if (res) {
      window.location.reload();
    } else {
      alert("Error al subir el archivo");
    }
  };

  const toBase64 = (file: globalThis.File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  let showShowcase = (
    showcasePriv: DataShowcase,
    loadingStatus: LoadingStatus
  ) => {
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        );
      case "rejected":
        return (
          <div
            className="flex-col text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar los datos
          </div>
        );
      case "resolved":
        return (
          <div
            className="flex-col"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
            }}
          >
            <table style={{ borderCollapse: "collapse" }}>
              <thead
                className="text-normal text-medium primary-color-35"
                style={{
                  background: "rgba(0,0,0,0.4)",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th></th>
                  <th>Dato</th>
                  <th>Valor</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                className="text-normal text-x-small primary-color"
                style={{
                  textAlign: "center",
                }}
              >
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {showcasePriv &&
                  showcasePriv.items.map((showcaseItem, i) => {
                    return (
                      <tr
                        key={"r" + showcaseItem.name}
                        style={{ height: "30px" }}
                      >
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {showcaseItem.name || ""}
                        </td>
                        <td>{showcaseItem.value || ""}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        );
    }
  };

  const renderAlerts = () => {
    if (loadingHyperflowAlerts == "pending") {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <LoadingOverlay
            active={true}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        </div>
      );
    } else {
      return hyperFlowAlerts &&
        hyperFlowAlerts.alerts &&
        hyperFlowAlerts.alerts.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "90%" }}>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "start",
              height: "60%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "95%", overflow: "auto", height: "100%" }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Servicio</th>
                    <th>Fecha y Hora</th>
                    <th>Categoría</th>
                    <th>Nombre</th>
                    <th>Criticidad</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {hyperFlowAlerts.alerts.map((alert, i) => {
                    return (
                      <tr
                        key={i}
                        className="list-hover"
                        style={{ height: "30px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentAlert(alert);
                        }}
                      >
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {getServiceNameAlerts(alert.service)}
                        </td>
                        <td>{formatDateStandar(alert.date)}</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {getCategoryNameAlerts(alert.category)}
                        </td>
                        <td>{`${currentFlow?.registration?.name} ${currentFlow?.registration?.lastName}`}</td>
                        <td>{getCriticidadAlertsRender(alert.criticality)}</td>
                        <td>{getStatusNameAlerts(alert.status)}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              marginTop: "2%",
              height: "50%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "60%", alignItems: "center" }}
            >
              <div
                className="flex-row"
                style={{
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="text-normal text-small primary-color-40"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    Detalle de la alerta
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "rgba(0,0,0,0.2)",
                      justifyContent: "center",
                      alignItems: "center",
                      boxSizing: "border-box",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    {currentAlert.id !== "" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <AlertDot levelAlert={currentAlert.criticality} />
                          <h4
                            style={{
                              margin: "0 0 0 10px",
                              color: "#fff",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {currentAlert.name}
                          </h4>
                        </div>
                        <h4
                          style={{
                            margin: "10px 0 10px 0px",
                            color: "rgba(255,255,255,0.5)",
                            fontSize: "11px",
                            fontWeight: 700,
                          }}
                        >
                          Descripción de la alerta
                        </h4>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#fff",
                          }}
                        >
                          {currentAlert.description}
                        </p>
                        {getCallToAction(currentAlert)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                changeAlertStatus({
                                  id: currentAlert.id,
                                  status:
                                    currentAlert.status === "CLOSED"
                                      ? "ACTIVE"
                                      : "CLOSED",
                                })
                              );
                              setCurrentAlert({
                                ...currentAlert,
                                status:
                                  currentAlert.status === "CLOSED"
                                    ? "OPEN"
                                    : "CLOSED",
                              });
                              dispatch(getHyperflowAlerts(currentFlow?.id!));
                              setCurrentAlert({
                                category: "",
                                criticality: "",
                                date: "",
                                description: "",
                                id: "",
                                name: "",
                                service: "",
                                status: "",
                                userName: "",
                                entityId: "",
                                userId: "",
                              });
                            }}
                            style={{
                              color: "#fff",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              border: "none",
                              borderRadius: "8px",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "4px 10px",
                              fontSize: "12px",
                              fontWeight: 700,
                            }}
                          >
                            {currentAlert.status === "CLOSED"
                              ? "Activar alerta"
                              : "Cerrar alerta"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="text-normal primary-color-40"
                        style={{
                          fontSize: "13px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        SELECCIONE UNA ALERTA PARA VER SU DESCRIPCIÓN
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "80%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingHyperflowAlerts === "idle"
              ? ""
              : "No existen alertas actualmente"}
          </h1>
        </div>
      );
    }
  };

  let showAttatchments = (
    currentAttachments: Array<Attatchment>,
    loadingStatus: LoadingStatus,
    downloadStatus: LoadingStatus
  ) => {
    let hasAuthentikator = false;
    currentAttachments.forEach((attatchment) => {
      if (attatchment.authentikatorData) {
        hasAuthentikator = true;
      }
    });
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        );

      case "rejected":
        return (
          <div
            className="flex-col text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar adjuntos
          </div>
        );
      case "resolved":
        if (!currentAttachments || currentAttachments.length === 0) {
          return (
            <div
              className="flex-col text-normal text-medium primary-color"
              style={{
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Este registro no tiene adjuntos
            </div>
          );
        } else {
          return (
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre adjunto</th>
                    <th>Nombre archivo</th>
                    <th>Descargar</th>
                    <th>Visualizar</th>
                    {hasAuthentikator && <th>Datos Authentikator</th>}
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {currentAttachments?.map((attatchment, i) => {
                    return (
                      <tr key={i} style={{ height: "30px" }}>
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {attatchment.fieldName || ""}
                        </td>
                        <td>{attatchment.fileName || ""}</td>
                        <td>
                          {downloadStatus === "pending" &&
                          selectedDownload === i ? (
                            <LoadingOverlay
                              active={downloadStatus === "pending"}
                              spinner={<ClipLoader color="white" size="20px" />}
                            />
                          ) : (
                            <DownloadIcon
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                downloadAtatchment(attatchment, i);
                              }}
                            />
                          )}
                        </td>{" "}
                        <td>
                          {downloadStatus === "pending" &&
                          selectedDownload === i ? (
                            <LoadingOverlay
                              active={downloadStatus === "pending"}
                              spinner={<ClipLoader color="white" size="20px" />}
                            />
                          ) : (
                            <EyeIcon
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                downloadAtatchment(attatchment, i, true);
                              }}
                            />
                          )}
                        </td>
                        {attatchment.authentikatorData && (
                          <td>
                            <div>
                              <EyeIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setAuthentikatorData(
                                    attatchment.authentikatorData ?? null
                                  );
                                  setShowAuthentikatorDataModal(true);
                                }}
                              />
                            </div>
                          </td>
                        )}
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <button
                className="flex-col"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  borderRadius: "10px",
                  background: "rgba(0,0,0,0.4)",
                  border: "none",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: 700,
                  marginTop: "20px",
                  padding: "10px 20px",
                  width: "fit-content",
                }}
                onClick={() => {
                  if (currentAttachments && currentAttachments.length > 0) {
                    dispatch(
                      getAttachmentsZip({
                        filledFormIds: getAttachmentIds(currentAttachments),
                        userId:
                          (currentFlow && currentFlow.registration?.id!) || "",
                      })
                    );
                  }
                }}
              >
                {downloadingAttatchmentZip === "pending" ? (
                  <ClipLoader color="white" size="30px" />
                ) : (
                  "Descargar todos los adjuntos"
                )}
              </button>
            </div>
          );
        }
      default:
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Adjuntos
          </div>
        );
    }
  };

  if (modoRevision && flows && flows.length == 0) {
    return (
      <LoadingOverlay
        active={loadingHyperFlow === "pending"}
        spinner={<ClipLoader color="white" size="150px" />}
      >
        {modoRevision && flows && flows.length == 0 && (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="text-large text-bold primary-color">
              {"No hay registros en revisión."}
            </h3>
          </div>
        )}
      </LoadingOverlay>
    );
  } else {
    return (
      <LoadingOverlay
        active={loadingHyperFlow === "pending"}
        spinner={<ClipLoader color="white" size="150px" />}
      >
        <Modal
          isOpen={modalDataAlertsOpen}
          onRequestClose={(e) => {
            setModalDataAlertsOpen(false);
          }}
          style={customStylesModal}
          contentLabel="Detalle"
          shouldCloseOnOverlayClick={true}
        >
          {currentFlow && currentFlow.registration && (
            <div
              className="flex-col text-medium text-bold"
              style={{
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                padding: "10px",
              }}
            >
              <h5
                style={{
                  fontWeight: 700,
                  color: "#000",
                }}
              >
                Datos corregidos
              </h5>
              <p style={{ fontWeight: 400 }}>
                Los siguientes datos han sido corregidos manualmente por el
                usuario
              </p>
              <div
                className="flex-row"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="flex-col">
                  <h6>Capturados con OCR</h6>
                  {valuesHasAlert(["firstName"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Nombre:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .firstName}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["lastName"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Apellido:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .lastName}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["documentNumber"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        No. de Documento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .documentNumber}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthDate"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Fecha de Nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .birthDate &&
                          currentFlow.registration.capturedUserDocument.birthDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthPlace"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Ciudad de nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .birthPlace &&
                          currentFlow.registration.capturedUserDocument.birthPlace.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthState"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Departamento de nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .birthState &&
                          currentFlow.registration.capturedUserDocument.birthState.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expeditionDate"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Fecha de expedición:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .expeditionDate &&
                          currentFlow.registration.capturedUserDocument.expeditionDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expiration"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Fecha de vencimiento
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .expirationDate &&
                          currentFlow.registration.capturedUserDocument.expirationDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expeditionCity"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Lugar de expedición:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .expeditionCity &&
                          currentFlow.registration.capturedUserDocument.expeditionCity.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["bloodType"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Tipo de sangre (RH)
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .bloodType &&
                          currentFlow.registration.capturedUserDocument.bloodType.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["gender"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Sexo
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.capturedUserDocument &&
                          currentFlow.registration.capturedUserDocument
                            .gender &&
                          currentFlow.registration.capturedUserDocument.gender.toUpperCase()}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex-col">
                  <h6>Corregidos por el usuario</h6>
                  {valuesHasAlert(["firstName"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Nombre:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument &&
                          currentFlow.registration.userDocument.firstName}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["lastName"]) && (
                    <>
                      {" "}
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Apellido:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument &&
                          currentFlow.registration.userDocument.lastName}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["documentNumber"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        No. de Documento:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.documentNumber}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthDate"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Fecha de Nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.birthDate &&
                          currentFlow.registration.userDocument.birthDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthPlace"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Ciudad de nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.birthPlace &&
                          currentFlow.registration.userDocument.birthPlace.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["birthState"]) && (
                    <>
                      <div
                        className="text-normal text-medium"
                        style={{ color: "#000" }}
                      >
                        Departamento de nacimiento:
                      </div>
                      <div
                        className="text-normal text-medium"
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.birthState &&
                          currentFlow.registration.userDocument.birthState.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expeditionDate"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Fecha de expedición:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.expeditionDate &&
                          currentFlow.registration.userDocument.expeditionDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expiration"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Fecha de vencimiento
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.expirationDate &&
                          currentFlow.registration.userDocument.expirationDate.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["expeditionCity"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Lugar de expedición:
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.expeditionCity &&
                          currentFlow.registration.userDocument.expeditionCity.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["bloodType"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Tipo de sangre (RH)
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.bloodType &&
                          currentFlow.registration.userDocument.bloodType.toUpperCase()}
                      </div>
                    </>
                  )}
                  {valuesHasAlert(["gender"]) && (
                    <>
                      <div
                        className="text-normal text-medium "
                        style={{ color: "#000" }}
                      >
                        Sexo
                      </div>
                      <div
                        className="text-normal text-medium "
                        style={{
                          color: "#000",
                          fontWeight: 400,
                          margin: "0px 0px 10px 0px",
                        }}
                      >
                        {currentFlow.registration.userDocument.gender &&
                          currentFlow.registration.userDocument.gender.toUpperCase()}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setModalDataAlertsOpen(false);
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "20%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                  marginTop: "20px",
                }}
              >
                Cerrar
              </div>
            </div>
          )}
        </Modal>
        <Modal
          isOpen={listDetailOpen}
          style={customStylesListDetail}
          onRequestClose={() => {
            setListDetailOpen(false);
            setListDetail([]);
          }}
        >
          <div className="flex-col">
            <div
              className="text-large text-bold"
              style={{ textAlign: "center" }}
            >
              Detalle de la lista
            </div>
            {listDetail.map((detail, i) => {
              return (
                <div
                  key={i}
                  className="text-small text-normal"
                  style={{
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {detail}
                </div>
              );
            })}
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setListDetailOpen(false);
                  setListDetail([]);
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "20%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        </Modal>
        {/* Modal AuthentikatorData */}
        <Modal
          isOpen={showAuthentikatorDataModal}
          onRequestClose={(e) => {
            setShowAuthentikatorDataModal(false);
          }}
          style={customStylesModalAuthentikator}
          contentLabel="Detalle"
          shouldCloseOnOverlayClick={true}
        >
          <AuthentikatorDataComponent data={authentikatorData} />
        </Modal>
        {!hyperflowError && (!currentFlow || currentFlow.id !== "") ? (
          <div
            className="flex-col"
            style={{
              width: "97%",
              height: "100%",
              boxSizing: "border-box",
              overflow: "auto",
              paddingLeft: "1%",
            }}
          >
            {currentFlow?.registration && (
              <div
                className="flex-row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex-row"
                  style={{
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <LeftArrow style={{ marginRight: "15px" }} />
                  <div className="text-bold text-x-large primary-color">
                    Regresar a lista de verificaciones
                  </div>
                </div>
              </div>
            )}

            {currentFlow?.registration && loadingHyperFlow !== "pending" && (
              <div className="flex-row">
                <div style={{ margin: "10px 0 10px 0", width: "55%" }}>
                  <div
                    className="text-medium primary-color"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "95%",
                      padding: "3% 0 10px 3%",
                    }}
                  >
                    {getRowInfoClient(
                      "Nombre y apellido:",
                      currentFlow?.registration && currentFlow.registration.name
                        ? `${currentFlow.registration.name} ${currentFlow.registration.lastName}`
                        : ""
                    )}
                    {getRowInfoClient(
                      "ID de flujo:",
                      currentFlow.id ? currentFlow.id : ""
                    )}
                    {getRowInfoClient(
                      "Email:",
                      currentFlow?.registration &&
                        currentFlow.registration.email
                        ? currentFlow.registration.email
                        : ""
                    )}
                    {getRowInfoClient(
                      "Nº Celular:",
                      currentFlow?.registration &&
                        currentFlow.registration.phone
                        ? currentFlow.registration.phone
                        : ""
                    )}
                    {/* <div className='flex-row'>
                      <div className='flex-col' style={{ width: "50%" }}>
                        Fecha del flujo:
                      </div>
                      <div className='flex-col' style={{ width: "50%" }}>
                        {formatDateStandar(currentFlow?.startDatetime)}
                      </div>
                    </div> */}

                    {roles.includes("tags.view") && getTagsRender()}
                  </div>
                </div>

                <div style={{ width: "45%" }}>
                  <div style={{ margin: "10px", marginRight: "0%" }}>
                    {(roles.includes("hyperflow.edit") ||
                      roles.includes("hyperflow.admin")) &&
                      getStateComponent()}
                  </div>
                </div>
              </div>
            )}
            {currentFlow?.registration &&
              loadingHyperFlow !== "pending" &&
              currentFlow.asesorId && (
                <div className="flex-row">
                  <div
                    style={{
                      margin: "5px 0 10px 0",
                      width: "50%",
                    }}
                  >
                    {getAsesorInfo(currentFlow.asesorId)}
                  </div>
                </div>
              )}

            {currentFlow?.registration ? (
              <div className="flex-row" style={{ height: "100%" }}>
                <div
                  className="flex-col"
                  style={{ height: "100%", width: "20%", margin: "0% 1% 0 0%" }}
                >
                  {getCurrentSectionMenu(
                    currentSectionStep
                      ? currentSectionStep.stepDescriptionId
                      : ""
                  )}
                </div>
                <div
                  className="flex-col"
                  style={{ width: "80%", overflow: "auto" }}
                >
                  {currentFlow &&
                    currentFlow.executedSteps &&
                    Object.values(currentFlow.executedSteps).map(
                      (e: ExecutedStep, i) => {
                        if (modoRevision) {
                          if (!currentSectionStep) {
                            setCurrentSection(e.id);
                            setCurrentSectionStep(e);
                          }
                        }
                        if (
                          e.stepDescriptionId === "MANUAL_RETRY" ||
                          ((e.stepDescriptionId === "CORE_ID" ||
                            e.stepDescriptionId === "MAGIC_FORMS" ||
                            e.stepDescriptionId === "ELECTRONIC_SIGNATURE" ||
                            e.stepDescriptionId === "PAGARE" ||
                            e.stepDescriptionId === "CHECK_LISTS") &&
                            e.entityId &&
                            e.entityId !== "")
                        ) {
                          if (
                            e.detailCore === null &&
                            e.detailMagic === null &&
                            e.detailSig === null &&
                            e.detailPagare === null &&
                            e.detailLists === null
                          ) {
                            <div>
                              <h1 className="text-huge text-bold primary-color">
                                {loadingHyperFlow === "pending" ||
                                loadingHyperFlow === "idle"
                                  ? ""
                                  : "Registro no se puede visualizar."}
                              </h1>
                            </div>;
                          } else {
                            if (getTitle(e.stepDescriptionId, e) === "Pagaré") {
                              if (e.detailPagare) {
                                return (
                                  <ExpandibleContent
                                    key={i}
                                    title={getTitle(e.stepDescriptionId, e)}
                                    isActive={currentSection === e.id}
                                    onClickFunction={() => {
                                      setCurrentSection(e.id);
                                      setCurrentSectionStep(e);
                                    }}
                                    content={
                                      getCurrentSectionContent(
                                        e.stepDescriptionId
                                      )!
                                    }
                                  />
                                );
                              } else {
                                return <div></div>;
                              }
                            }

                            return (
                              <ExpandibleContent
                                key={i}
                                title={getTitle(e.stepDescriptionId, e)}
                                isActive={currentSection === e.id}
                                onClickFunction={() => {
                                  setCurrentSection(e.id);
                                  setCurrentSectionStep(e);
                                }}
                                content={
                                  getCurrentSectionContent(e.stepDescriptionId)!
                                }
                              />
                            );
                          }
                        }
                      }
                    )}

                  {customStates &&
                    customStates.customStates &&
                    Object.values(customStates.customStates).map((item, i) => {
                      if (
                        item.stepId !== currentFlow?.currentStepId &&
                        item.selectedState === ""
                      ) {
                        return <div></div>;
                      } else if (
                        item.stepId !== currentFlow?.currentStepId &&
                        item.selectedState !== ""
                      ) {
                        return (
                          <ExpandibleContent
                            key={i}
                            title={item.tabName}
                            isActive={currentSection === item.stepId}
                            onClickFunction={() => {
                              setCurrentSection(item.stepId);
                            }}
                            content={getCustomStateExpandible(item)}
                          />
                        );
                      }
                    })}
                  {currentFlow?.registration && (
                    <ExpandibleContent
                      title="Adjuntos"
                      isActive={currentSection === "Adjuntos"}
                      contentHeight="50%"
                      onClickFunction={() => {
                        setCurrentSection("Adjuntos");
                        setCurrentSectionStep(null);
                      }}
                      content={
                        <div className="flex-col">
                          <div
                            className="flex-col"
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            {showAttatchments(
                              currentAttachments,
                              loadingAttatchments,
                              downloadingAttatchment
                            )}
                          </div>
                        </div>
                      }
                    />
                  )}
                  <ExpandibleContent
                    title="Alertas"
                    isActive={currentSection === "Alertas"}
                    contentHeight="50%"
                    onClickFunction={() => {
                      setCurrentSection("Alertas");
                      setCurrentSectionStep(null);
                    }}
                    content={
                      <div className="flex-col">
                        <div
                          className="flex-col"
                          style={{
                            border: "1px solid rgba(0,0,0,0.2)",
                            boxSizing: "border-box",
                          }}
                        >
                          {renderAlerts()}
                        </div>
                      </div>
                    }
                  />
                  {currentFlow && dataShowcase?.showShowcase === true && (
                    <ExpandibleContent
                      title="Datos"
                      isActive={currentSection === "Datos"}
                      contentHeight="50%"
                      onClickFunction={() => {
                        setCurrentSection("Datos");
                        setCurrentSectionStep(null);
                      }}
                      content={
                        <div className="flex-col">
                          <div
                            className="flex-col"
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            {showShowcase(dataShowcase, loadingShowcase)}
                          </div>
                        </div>
                      }
                    />
                  )}
                  {currentFlow && (
                    <ExpandibleContent
                      title="Archivos de soporte"
                      isActive={currentSection === "ArchivosSoporte"}
                      contentHeight="50%"
                      onClickFunction={() => {
                        setCurrentSection("ArchivosSoporte");
                        setCurrentSectionStep(null);
                      }}
                      content={
                        <div className="flex-col">
                          <div
                            className="flex-col"
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            {showSupportFiles(currentFlow.supportFiles ?? [])}
                          </div>
                        </div>
                      }
                    />
                  )}
                  {currentFlow && manualInputData.showChangelableDataWindow && (
                    <ExpandibleContent
                      title="Modificar Datos"
                      isActive={currentSection === "Modificar Datos"}
                      contentHeight="50%"
                      onClickFunction={() => {
                        setCurrentSection("Modificar Datos");
                        setCurrentSectionStep(null);
                      }}
                      content={
                        <div className="flex-col">
                          <div
                            className="flex-col"
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            <ManualInputComponent
                              items={manualInputData.items}
                              status={loadingManualInputData}
                              onChange={(id, data) => {
                                dispatch(changeManualInputState({ id, data }));
                              }}
                              onSubmit={() => {
                                dispatch(resolveManualInputData());
                              }}
                            />
                          </div>
                        </div>
                      }
                    />
                  )}
                  {currentFlow && (
                    <ExpandibleContent
                      title="Estado del flujo"
                      isActive={currentSection === "Estado del flujo"}
                      contentHeight="50%"
                      onClickFunction={() => {
                        setCurrentSection("Estado del flujo");
                        setCurrentSectionStep(null);
                      }}
                      content={
                        <div className="flex-col">
                          <div
                            className="flex-col"
                            style={{
                              border: "1px solid rgba(0,0,0,0.2)",
                              boxSizing: "border-box",
                            }}
                          >
                            {showFlowStatus(currentFlow)}
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3 className="text-large text-bold primary-color">
                  {loadingHyperFlow === "pending" || loadingHyperFlow === "idle"
                    ? ""
                    : "Usuario no ha culminado el registro. No se puede visualizar la información."}
                </h3>
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: "5%",
            }}
          >
            <h1
              className="text-huge text-bold primary-color"
              style={{ textAlign: "center" }}
            >
              {loadingHyperFlow === "rejected" && getNotFoundError()}
            </h1>
          </div>
        )}
        {attachmentToVisualize.url && (
          <div
            className="flex-col"
            style={{
              position: "absolute",
              left: "1%",
              top: "5%",
              height: "90%",
              width: "97%",
            }}
          >
            <button
              onClick={() =>
                dispatch(
                  setAttachmentToVisualize({
                    fileName: "",
                    mimeType: "",
                    url: "",
                  })
                )
              }
              style={{
                display: "flex",
                flexDirection: "row",
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                fontSize: "25px",
                background: "#1A617D",

                width: "100%",
                color: "white",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 20px",
              }}
            >
              <p> </p> <p style={{ margin: 0 }}> Cerrar</p>
              <p style={{ margin: 0, fontSize: "30px", fontWeight: "bold" }}>
                X
              </p>
            </button>
            {getAttachmentRender(attachmentToVisualize.mimeType)}
          </div>
        )}
      </LoadingOverlay>
    );
  }
};
